import React from 'react'
import {Routes, Route} from 'react-router-dom';

import {
    Consumers,
    Dashboard,
    Login,
    Logout,
    Providers,
    Location,
    Pipeline,
    AgentFinder,
    AgentDb,
    AccountsReceivable, RewardsLog
} from './pages';
import AgentProfile from './pages/AgentFinder/AgentProfile';

function AppRoutes() {
        return (
            <Routes>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard/*" element={<Dashboard/>}/>
                    <Route path="/providers/*" element={<Providers/>}/>
                    <Route path="/consumers/*" element={<Consumers/>}/>
                    <Route path="/location" element={<Location/>}/>
                    <Route path="/pipeline" element={<Pipeline/>}/>
                    <Route path="/accounts-receivable" element={<AccountsReceivable/>}/>
                    <Route path="/agent-finder/:consumerId/:dealId" element={<AgentFinder/>}/>
                    <Route path="/agent-finder/:location" element={<AgentFinder/>}/>
                    <Route path="/agent-finder" element={<AgentFinder/>}/>
                    <Route path="/agent-finder/agent-profile/:id" element={<AgentProfile/>}/>
                    <Route path="/agent-db/*" element={<AgentDb/>}/>
                    <Route path="/rewards-log" element={<RewardsLog/>}/>
            </Routes>
        );
}

export default AppRoutes;