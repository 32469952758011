const accent = '#0d3f88';
const lightBlue = '#1e7fe8';
const green = '#39a128';
const lightGreen = '#72c47c';

const _ = {
    width: '1170px',

    accent,
    lightBlue,
    green,
    lightGreen,

    header: {
        color: '#fff',
        background: accent,
    },

    cta: {
        color: '#fff',
        background: '#7fccf7'
    },

    page: {
        background: '#e9ebee'
    },

    input: {
        color: '#000',
        background: '#f4f4f4',
        border: '1px solid #c6c6c6',
        height: '44px',
    },

    button: {
        color: '#fff',
        background: accent,

        subtle: {
            color: accent,
            background: '#fff',
            border: `1px solid ${accent}`
        },

        transparent: {
            color: accent,
            background: 'transparent',
            border: `1px solid ${accent}`
        },

        white: {
            color: accent,
            background: '#fff',
            border: '1px solid #026'
        },

        green: {
            color: '#fff',
            background: green
        },

        'light-blue': {
            color: '#fff',
            background: `${lightBlue}`
        },

        'red': {
            color: '#fff',
            background: '#800'
        }
    },
};

export default _;