import React, {ChangeEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";

import {Button, Buttons, Form, Input, Label, Select, TextArea} from "../../components/form";
import AutoCompleteLocation from "../../components/form/AutoCompleteLocation";
import Overlay from "../../components/Overlay";
import AutoCompleteGooglePlaces from "../../components/form/AutoCompleteGooglePlaces";
import formatNumber from "format-number";
import {FaTimesCircle} from "react-icons/fa";
import {Consumer} from "../../gql/types/graphql";
import {useMutation} from "@apollo/client";
import {updateConsumerMutationDocument} from "../../gql/Consumer";
import deepEqual from "deep-equal";

export const currencyInt = formatNumber({prefix:'$', truncate:0});

interface Deal {
    type: 'Buy' | 'Sell';
    location: { zip: string, cityState: string };
    timeframe: string;
    connectBy: string;
    priorAgreementWithAgent: boolean;
    notes: string;
}

interface BuyDeal extends Deal {
    type: 'Buy';
    addresses: string[];
    minPrice: number;
    maxPrice: number;
}

interface SellDeal extends Deal {
    type: 'Sell';
    address: string;
    price: number;
}

const HOW_THEY_FOUND_RBN = [
    '',
    'AmEx',
    'Facebook',
    'Friend',
    'IG',
    'Nextdoor',
    'Reddit',
    'TPG',
    'Other'
]

interface ConsultationProps {
    consumer: Consumer,
    close: () => void
}

const Consultation:React.FC<ConsultationProps> = ({consumer, close}) => {
    const [deals, setDeals] = useState<Deal[]>(consumer.consultation?.deals || []);

    const inquiry = 0 < consumer.inquiries.nodes.length ? consumer.inquiries.nodes[0] : undefined;
    const details = inquiry?.details;

    const [consultation, setConsultation] = useState({
        agentAvailable: consumer.consultation?.agentAvailable || '',
        prepNotes: consumer.consultation?.prepNotes || '',
        personalizationParagraph: consumer.consultation?.personalizationParagraph || '',
        preApprovedToBuy: consumer.consultation?.preApprovedToBuy || '',
        howTheyFoundRBN: consumer.consultation?.howTheyFoundRBN || '',
        notes: consumer.consultation?.notes || '',
        followUp: consumer.consultation?.followUp || ''
    });

    const closeHandler = (e?:any) => {
        if (typeof e?.preventDefault === 'function') e.preventDefault();

        if (e.target === e.currentTarget)
            close();
    }

    const [updateConsumer] = useMutation(updateConsumerMutationDocument);

    const [saving, setSaving] = useState(false);

    const patch = useMemo(() => ({
        consultation: {
            ...consultation,
            deals
        }
    }), [consultation, deals]);

    const dirty = useMemo(() => !deepEqual(consumer.consultation, patch.consultation, { strict: true }), [consumer.consultation, patch]);

    const saveHandler = (e?:any) => {
        if (typeof e?.preventDefault === 'function') e.preventDefault();

        setSaving(true);

        updateConsumer({
            variables: {
                input: {
                    nodeId: consumer.nodeId,
                    patch
                }
            }
        }).then(() => setSaving(false));
    }

    const addDeal = useCallback((side: string) => {
        setDeals(_deals => {
            const deals = _deals.slice();

            const location = {
                zip: details.location.id,
                cityState: details.location.label
            };

            if (side.match(/Buy|Both/)) {
                const [min, max] = details.buying.price.split(/\s+-\s+/);

                const deal: BuyDeal = {
                    type: 'Buy',
                    location: {...location},
                    minPrice: Number(min?.replace(/\D+/g, '') || 0),
                    maxPrice: Number(max?.replace(/\D+/g, '') || 0),
                    addresses: [],
                    timeframe: '',
                    connectBy: '',
                    priorAgreementWithAgent: false,
                    notes: ''
                };

                deals.push(deal);
            }

            if (side.match(/Sell|Both/)) {
                const deal: SellDeal = {
                    type: 'Sell',
                    location: {...location},
                    price: Number(details.selling.price.replace(/\D+/g, '') || 0),
                    address: '',
                    timeframe: '',
                    connectBy: '',
                    priorAgreementWithAgent: false,
                    notes: ''
                };

                deals.push(deal);
            }

            return deals;
        });
    }, [details, setDeals]);

    useEffect(() => {
        if (!details)
            return;

        if (deals.length === 0)
            addDeal(details.service);
    }, [details, deals, addDeal]);

    const addDealHandler: ChangeEventHandler<HTMLSelectElement> = e => {
        const side = e.target.value;

        addDeal(side);
    }

    const updateDeal: (i: number) => (deal: Deal) => void = (i) => deal => {
        setDeals(_deals => {
            const deals = _deals.slice();

            deals[i] = deal;

            return deals;
        });
    };

    const removeDeal: (i: number) => () => void = (i) => () => {
        setDeals(_deals => {
            const deals = _deals.slice();

            deals.splice(i, 1);

            return deals;
        });
    }

    const updateField: (field: string) => ChangeEventHandler<HTMLInputElement> = (field) => e => {
        setConsultation(_consultation => {
            return {..._consultation, [field]: e.target.value};
        });
    }

    const hasBuyDeal = deals.some(deal => deal.type === 'Buy');

    const [howTheyFoundRBN, setHowTheyFoundRBN] = useState(consultation.howTheyFoundRBN);

    const handleSetHowTheyFound: ChangeEventHandler<HTMLSelectElement> = e => {
        let value = e.target.value;

        setHowTheyFoundRBN(value);

        if (value === 'Other')
            value = '';

        setConsultation(_consultation => {
            return {..._consultation, howTheyFoundRBN: value};
        });
    }

    return (
        <Overlay>
            <Popup>
                <StyledForm>
                    <h2>Consultation for {consumer?.account?.firstName} {consumer?.account?.lastName}</h2>
                    <Label><span>AmEx Connected</span><div>{consumer?.vendors?.amex?.walletId ? 'Yes' : 'No'}</div></Label>
                    <Input label="Agent Available" value={consultation.agentAvailable} onChange={updateField('agentAvailable')} id={`agent-available-${consumer.id}`}/>
                    <SmallTextArea label="Prep Notes" value={consultation.prepNotes} onChange={updateField('prepNotes')} id={`prep-notes-${consumer.id}`}/>
                    <SmallTextArea label="Personalized paragraph for email" value={consultation.personalizationParagraph} onChange={updateField('personalizationParagraph')} id={`personalized-p-${consumer.id}`}/>

                    {deals.map((deal, i) => (
                        deal.type === 'Buy' ?
                            <Buy key={i} deal={deal as BuyDeal} update={updateDeal(i)} remove={removeDeal(i)} index={i}/> :
                            <Sell key={i} deal={deal as SellDeal} update={updateDeal(i)} remove={removeDeal(i)} index={i}/>
                    ))}

                    {hasBuyDeal && (
                        <Input label="Pre-approved to Buy" value={consultation.preApprovedToBuy} onChange={updateField('preApprovedToBuy')} id={`pre-approved`}/>
                    )}

                    <Select label="How they found RBN" items={HOW_THEY_FOUND_RBN} value={HOW_THEY_FOUND_RBN.includes(howTheyFoundRBN) ? howTheyFoundRBN : 'Other'} onChange={handleSetHowTheyFound} id={`how-they-found-rbn`}/>
                    {(howTheyFoundRBN === 'Other' || !HOW_THEY_FOUND_RBN.includes(howTheyFoundRBN)) && (
                        <Input label=" " placeholder="How they found RBN" value={consultation.howTheyFoundRBN} onChange={updateField('howTheyFoundRBN')} id={`how-they-found-rbn-detail`}/>
                    )}

                    <SmallTextArea label="Misc. Notes" value={consultation.notes} onChange={updateField('notes')} id={`misc-notes`}/>

                    <Input label="Follow Up Date (EB Task Date)" type="date" value={consultation.followUp} onChange={updateField('followUp')} id={`follow-up-by`}/>

                    <AddDeal>
                        <Select label="Add Transaction" items={['Select Side', 'Buy', 'Sell']} value={'Side?'} onChange={addDealHandler} id={`add-transaction`}/>
                    </AddDeal>

                    <Buttons>
                        <Button onClick={closeHandler} variation="transparent" disabled={saving}>{dirty ? 'Cancel' : 'Close'}</Button>
                        <Button onClick={saveHandler} disabled={!dirty || saving}>{saving ? 'Saving...' : 'Save'}</Button>
                    </Buttons>
                </StyledForm>
            </Popup>
        </Overlay>
    )
};

export default Consultation;

const StyledForm = styled(Form)`
`;

const Popup = styled.div`
  background: #fff;
  padding: 1rem;
  max-height: 90vh;
  overflow: auto;

  h2 {
    margin: 0 0 .5rem;
  }

  ${Buttons} {
  }

  label {
    display: grid;
    grid-template-columns: 250px 1fr;
    align-items: center;
  }
  
  label > span {
    padding-right: 1em;
    text-align: right;
  }
  
  label > input:first-child {
    grid-column: span 2;
  }
`;

const SmallTextArea = styled(TextArea)`
  min-height: 4em;
`;

const AddDeal = styled.div`
    margin-top: 1rem;
`;

const DealContainer = styled.div`
  position: relative;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  padding-top: .5rem;
  margin-bottom: .5rem;
  
  & + & {
    border-top: none;
  }
  
  svg {
    position: absolute;
    top: 1rem;
    left: 0;
    cursor: pointer;
  }
`;

interface BuySellProps<T extends Deal> {
    deal: T;
    update: (deal: Deal) => void;
    remove: () => void;
    index: number;
}

const Buy: React.FC<BuySellProps<BuyDeal>> = ({deal, update, remove, index}) => {
    const confirmRemove = () => {
        if (window.confirm(`Are you sure you want to remove this ${deal.type} deal?`))
            remove();
    }

    const updateField: (field: string) => ChangeEventHandler<HTMLInputElement> = (field) => e => {
        let value = e.target.value;

        if (e.target.type === 'checkbox') {
            update({...deal, [field]: !((deal as any)[field] as any)});
        }
        else if (field.match(/price/i)) {
            value = value.replace(/\D+/g, '')
                .replace(/^0+(\d)/, '$1');

            if (!value)
                value = '0';

            update({...deal, [field]: Number(value)} as BuyDeal);
        }
        else {
            update({...deal, [field]: value} as BuyDeal);
        }
    }

    const onSelectAddress = (data: any) => {
        console.log(data);

        const addresses = deal.addresses.slice();
        addresses.push(data.label);

        update({...deal, addresses} as BuyDeal);
    }

    const addressChange: (i: number) => ChangeEventHandler<HTMLInputElement> = (i) => e => {
        const addresses = deal.addresses.slice();
        const value = e.target.value;

        if (value)
            addresses[i] = e.target.value;
        else
            addresses.splice(i, 1);

        update({...deal, addresses} as BuyDeal);
    }

    const setLocation = (data: any) => {
        if (!(data.id && data.label))
            return;

        const location = {
            zip: data.id,
            cityState: data.label
        };

        update({...deal, location});
    };

    return (
        <DealContainer>
            <FaTimesCircle color="#900" onClick={confirmRemove}/>
            <AutoCompleteLocation label={`${deal.type} Location`} onSelect={setLocation} value={deal.location} id={`buy-location-${index}`} defaultValue={deal.location ? {id: deal.location.zip, label: deal.location.cityState} : undefined}/>
            <Label>
                <span>Addresses</span>
                <div>
                    {deal.addresses.map((address, i) => (
                        <Input key={i} value={address} onChange={addressChange(i)} id={`address-${i}-${index}`}/>
                    ))}
                    <AutoCompleteGooglePlaces key={deal.addresses.length+1} placeholder="Add favorite address" onSelect={onSelectAddress} id={`add-favorite-${index}`}/>
                </div>
            </Label>
            <Label>
                <span>Budget</span>
                <PriceRange>
                    <Input value={currencyInt(deal.minPrice)} onChange={updateField('minPrice')} id={`min-price-${index}`}/>
                    <span>&nbsp;-&nbsp;</span>
                    <Input value={currencyInt(deal.maxPrice)} onChange={updateField('maxPrice')} id={`max-price-${index}`}/>
                </PriceRange>
            </Label>
            <Input label="Timeframe" value={deal.timeframe} onChange={updateField('timeframe')} id={`timeframe-${index}`}/>
            <Input label="Connect to an Agent by" type="date" value={deal.connectBy} onChange={updateField('connectBy')} id={`connect-by-${index}`}/>
            <Label><span>Prior Agreement with Agent</span><div><input type="checkbox" checked={deal.priorAgreementWithAgent} onChange={updateField('priorAgreementWithAgent')}/></div></Label>
            <Input label="Transaction Notes" value={deal.notes} onChange={updateField('notes')} id={`txn-notes-${index}`}/>
        </DealContainer>
    )
}

const Sell: React.FC<BuySellProps<SellDeal>> = ({deal, update, remove, index}) => {
    const confirmRemove = () => {
        if (window.confirm(`Are you sure you want to remove this ${deal.type} deal?`))
            remove();
    }

    const updateField: (field: string) => ChangeEventHandler<HTMLInputElement> = (field) => e => {
        let value = e.target.value;

        if (e.target.type === 'checkbox') {
            update({...deal, [field]: !((deal as any)[field] as any)});
        }
        else if (field.match(/price/i)) {
            value = value.replace(/\D+/g, '')
                .replace(/^0+(\d)/, '$1');

            if (!value)
                value = '0';

            update({...deal, [field]: Number(value)} as SellDeal);
        }
        else {
            update({...deal, [field]: value} as SellDeal);
        }
    }

    const onSelectAddress = (data: any) => {
        update({...deal, address: data.label} as SellDeal);
    }

    const setLocation = (data: any) => {
        if (!(data.id && data.label))
            return;

        const location = {
            zip: data.id,
            cityState: data.label
        };

        update({...deal, location});
    };

    return (
        <DealContainer>
            <FaTimesCircle color="#900" onClick={confirmRemove}/>
            <AutoCompleteLocation label={`${deal.type} Location`} onSelect={setLocation} value={deal.location} id={`sell-location-${index}`} defaultValue={deal.location ? {id: deal.location.zip, label: deal.location.cityState} : undefined}/>
            <Label>
                <span>Address</span>
                {deal.address ?
                    <Input value={deal.address} onChange={updateField('address')} id={`sell-address-${index}`}/> :
                    <AutoCompleteGooglePlaces placeholder="Address of the house to sell" onSelect={onSelectAddress} id={`sell-autocomplete-${index}`}/>
                }
            </Label>
            <Price><Input label="Expected List Price" value={currencyInt(deal.price)} onChange={updateField('price')} id={`list-price-${index}`}/></Price>
            <Input label="Timeframe" value={deal.timeframe} onChange={updateField('timeframe')} id={`sell-timeframe-${index}`}/>
            <Input label="Connect to an Agent by" type="date" value={deal.connectBy} onChange={updateField('connectBy')} id={`sell-connect-by-${index}`}/>
            <Label><span>Prior Agreement with Agent</span><div><input type="checkbox" checked={deal.priorAgreementWithAgent} onChange={updateField('priorAgreementWithAgent')}/></div></Label>
            <Input label="Transaction Notes" value={deal.notes} onChange={updateField('notes')} id={`sell-txn-notes-${index}`}/>
        </DealContainer>
    )
}

const PriceRange = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding-bottom: 15px;
  
  label {
    margin-bottom: 0;
  }
  
  input {
    text-align: right;
  }
`;

const Price = styled.div`
    input {
      text-align: right;
    }
`;
