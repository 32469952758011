import {Maybe, PotentialProviderFragmentFragment} from "../../gql/types/graphql";
import styled from "styled-components";

interface Props {
    agent: Maybe<PotentialProviderFragmentFragment> | undefined
}

export const AgentPresentedSelected: React.FC<Props> = ({agent}) => {
    const max = Math.max(agent?.selectedNames?.length ?? 0, agent?.presentedNames?.length ?? 0);

    const cells: string[] = [];

    for (let i = 0; i < max; i++) {
        cells.push(agent?.selectedNames?.[i] ?? '');
        cells.push(agent?.presentedNames?.[i] ?? '');
    }

    return (
        <Container>
            {agent?.selectedNames?.length ?? 0}:{agent?.presentedNames?.length ?? 0}
            {0 < max && <Details>
                <Heading>Selected By</Heading>
                <Heading>Presented To</Heading>
                {cells.map((cell, i) => (
                    <div key={i}>{cell}</div>
                ))}
            </Details>}
        </Container>
    );
};

const Heading = styled.div`
    font-weight: bold;
`;

const Details = styled.div`
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    transform: translateX(-30%);
    display: none;
    grid-template-columns: 1fr 1fr;
    column-gap: 1em;
    row-gap: .25em;
    background: #fff;
    border: 1px solid #ccc;
    padding: 1rem;
    
    & > div {
        white-space: nowrap;
    }
`;

const Container = styled.span`
    position: relative;
    overflow: visible;
    
    &:hover ${Details} {
        display: grid;
    }
`;

