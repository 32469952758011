import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import AuthenticatedPage from "../../components/AuthenticatedPage";
import useAllConsumers from "../../lib/useAllConsumers";
import {useQuery} from "@apollo/client";
import providersQueryDocument from "../../gql/Providers";
import {ConsumerDataFragment, ProviderDataFragment} from "../../gql/types/graphql";
import {useFragment} from "../../gql/types";
import {fragments} from "../../gql/Provider";

const StyledMap = styled.div`
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

const w:any = window;

// const greenMarker = {
//     path: "M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z",
//     anchor: new google.maps.Point(12, 17),
//     fillOpacity: 1,
//     fillColor: '#0f0',
//     strokeWeight: 1,
//     strokeColor: 'black',
//     scale: 1.5
// }
//
// const yellowMarker = Object.assign({}, greenMarker, {fillColor: '#ff0'});
//
// function mapPoints(providers:Providers_allProviders_nodes[], markers:google.maps.Marker[], map:google.maps.Map): void {
//     markers.forEach(marker => marker.setMap(null));
//     markers.length = 0;
//
//     const addMarker = ({public: isPublic, areaCenter:{latitude: lat, longitude: lng}}:Providers_allProviders_nodes) => {
//         if (!(typeof lat === 'number' && typeof lng === 'number'))
//             return;
//
//         markers.push(new google.maps.Marker({
//             position: {lat, lng},
//             map,
//             icon: isPublic ? greenMarker : yellowMarker
//         }))
//     };
//
//     providers
//         .filter(provider => provider.areaCenter && !provider.public)
//         .forEach(addMarker);
//
//     providers
//         .filter(provider => provider.areaCenter && provider.public)
//         .forEach(addMarker);
// }

interface Position {
    latitude:number,
    longitude:number,
    label?:string,
}

function mapPoints(consumers:ConsumerDataFragment[], clusterer:MarkerClusterer|undefined, positions:{[id:string]: Position}): void {
    const addMarker = (position:Position) => {
        const marker = new google.maps.Marker({
            position: {lat: position.latitude, lng: position.longitude},
            title: position.label
        });

        if (clusterer)
            clusterer.addMarker(marker);
    }

    consumers.forEach(consumer => {
        consumer.inquiries.nodes
            .forEach(inquiry => {
                if (positions[inquiry.id] || !inquiry.details.location?.position)
                    return;

                const position:Position = {...inquiry.details.location.position, label: inquiry.details.location.label};

                positions[inquiry.id] = position;
                addMarker(position);
            });
    });
}

function heatmap(providers: ProviderDataFragment[], heatmap:google.maps.visualization.HeatmapLayer, map:google.maps.Map): void {
    const data = providers.map(provider => {
        const {public: isPublic, areaCenter:{latitude: lat, longitude: lng}} = provider;

        return {
            location: new google.maps.LatLng(lat, lng),
            weight: isPublic ? 2 : 1
        }
    });

    heatmap.setData(data);

    if (!heatmap.getMap())
        heatmap.setMap(map);
}

const Map = () => {
    const {data} = useQuery(providersQueryDocument);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const providers = data?.allProviders?.nodes.map(node => useFragment(fragments.provider, node));
    const consumers = useAllConsumers();

    const [map, setMap] = useState<google.maps.Map|null>(null);
    const markersRef = useRef<Array<google.maps.Marker>>([]);
    const heatmapRef = useRef<google.maps.visualization.HeatmapLayer>(new google.maps.visualization.HeatmapLayer({
        data: [],
        dissipating: true,
        radius: 60,
        gradient: ['rgba(128,128,0,0)', '#ff0', '#0f0'], //'rgb(13, 63, 136)'],
        maxIntensity: 1
    }));
    const markerClusterRef = useRef<MarkerClusterer|undefined>();
    const positionsRef = useRef<{[id:string]: Position}>({});

    useEffect(() => {
        if (!(providers && map))
            return;

        heatmap(providers.filter(provider => !!provider.areaCenter), heatmapRef.current, map);
    }, [providers, map]);

    useEffect(() => {
        if (consumers && map)
            mapPoints(consumers, markerClusterRef.current, positionsRef.current);
    }, [consumers, map]);

    const mapRef = useCallback((element:any) => {
        if (map || !element)
            return;

        const gMap = new w.google.maps.Map(element, {
            center: {lat: 40.8097343, lng: -95.5556199},
            zoom: 4,
            clickable: true,
            streetViewControl: false
        });

        setMap(gMap);

        markerClusterRef.current = new MarkerClusterer({map: gMap, markers: markersRef.current});
    }, [map, setMap]);

    return (
        <StyledMap ref={mapRef}/>
    );
};

const MapPage = () => {
    return (
        <AuthenticatedPage title="Providers Map" fullWidth={true} padding="0">
            <Map/>
        </AuthenticatedPage>
    )
};

export default MapPage;