import React from 'react';

import AuthenticatedPage from "../../components/AuthenticatedPage";
import {COLUMNS, ConsumersList} from "../Consumers/Consumers";
import {useAllConsumerDeals} from "../../lib/useAllConsumers";

const MILESTONES = [
    'In Escrow',
    'Property Details Received',
    'Points Estimate Sent',
    'Payment Instructions Sent',
    'Payment Instructions Confirmed',
    'Closed',
    'Payment Sent',
    'Payment Received',
    'Test Points Issued',
    'Waiting for AmEx Connection',
    'Points Issued - Awaiting Payment',
];

const memberLeadsTrack = (c:any) => {
    if (!c)
        return false;

    return (
        c.tracks.some((track:string) => track === 'Transactions') &&
            c.milestones.some((milestone:string) => MILESTONES.includes(milestone))
        // c.milestones.some((milestone:string) => MILESTONES.includes(milestone)) ||
        // c.buckets.some((bucket:string) => BUCKETS.includes(bucket))
    );
};

const AR_COLUMNS = COLUMNS.filter(column => !column.label || !['Buy Price', 'Sell Price', 'Progress', 'Created', 'Appt', 'Connect By'].includes(column.label));
AR_COLUMNS.push({ label: 'Close Date', field: 'close', width: '105px', align: 'right', sort: 'closeDate' });
AR_COLUMNS.push({ label: 'Agent', field: 'agentSelected', width: '1fr', sort: true, render: data => {
        // console.log(e);

        const agent = data.deal.agent;

        return (
            <a href={`https://joinrbn.engagebay.com/home#list/0/subscriber/${agent?.id}`} target="engagebay">{data.agentSelected}</a>
        );

        // if (!id)
        //     return '';
        //
        // const inquiry = e.row.original.inquiry;
        //
        // return (
        //     <Links>
        //         <a href={`https://joinrbn.engagebay.com/home#deal/${id}`} target="engagebay">EB</a>
        //         <span title={formatCampaign(e.row.original.campaign)}>&copy;</span>
        //         {inquiry && <Flags inquiry={inquiry} dealId={id}/>}
        //     </Links>
        // );
    }});

const DEFAULT_SORT = {
    column:AR_COLUMNS.length - 2,
    descending: true
};

const AccountsReceivable = () => {
    const consumers = useAllConsumerDeals();

    return (
        <AuthenticatedPage title="Accounts Receivable" fullWidth={true} padding="0 0 1rem">
            <ConsumersList consumers={consumers} filter={memberLeadsTrack} columns={AR_COLUMNS} defaultSort={DEFAULT_SORT}/>
        </AuthenticatedPage>
    );
};

export default AccountsReceivable;
