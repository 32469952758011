import React, {
    ChangeEvent,
    ChangeEventHandler,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import styled from 'styled-components';
import {AgentFinderContext} from "./AgentFinder";
import useViewer from "../../lib/use-viewer";
import {Button, Buttons, Label} from "../../components/form";
import {AgentBlurb} from "./AgentBlurb";
import {useParams} from "react-router";
import {api, JWT_KEY} from "../../config";
import {useQuery} from "@apollo/client";
import ConsumerByIdQuery from "../../gql/Consumer";
import {Consumer, Inquiry, PotentialProvider} from "../../gql/types/graphql";

const EMAIL_URL = `https://api.joinrbn.com/email/consumer/present-agents-v2`;

interface Props {
    consumer: Consumer;
    deal: any;
    agents: PotentialProvider[];
    close: () => void;
}

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000088;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    z-index: 65535;
`;

const Popup = styled.div`
    background: #fff;
    padding: 1em;
    max-height: 90vh;
    min-width: 640px;
    display: flex;
    flex-direction: column;
`;

const AgentWrapper = styled.div`
    display: flex;
    
    a {
        margin-right: 1em;
        white-space: nowrap;
    }
    
    margin: 2em 0;
`;

const Blurb = styled.div`
    width: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-rows: auto 1fr;    
`;

interface AgentProps {
    inquiry: Inquiry | undefined;
    data: PotentialProvider;
}

const Agent:React.FC<AgentProps> = ({inquiry, data}) => {
    const agent = data.provider;

    const photo = agent?.image?.url;
console.log({agent})
    const photoStyle = {
        flexShrink: 0,
        width: '80px',
        height: '80px',
        backgroundImage: `url("https://api.joinrbn.com${photo}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        borderRadius: '40px',
        marginRight: '20px',
    }

    const blurbId = `blurb-${agent?.id}`;

    const checkBlurbHeight = useCallback(() => {
        const blurb = document.getElementById(blurbId);

        if (!blurb)
            return;

        const hasScrollBar = blurb.offsetHeight < blurb.scrollHeight;

        blurb.style.background = hasScrollBar ? '#f99' : '#fff';
    }, [blurbId]);

    useEffect(() => {
        window.requestAnimationFrame(checkBlurbHeight);
    }, [checkBlurbHeight]);

    return (
        <AgentWrapper>
            <div style={photoStyle} />
            <Blurb>
                <AgentBlurb inquiry={inquiry} potentialProvider={data}/>
            </Blurb>
        </AgentWrapper>
    );
}

export const PresentAgents:React.FC<Props> = ({consumer, deal, agents, close}) => {
    const {viewer} = useViewer();
    const [signature, setSignature] = useState('');
    const consultation = consumer.consultation;
    const cBuy = consultation?.deals?.find((deal:any) => deal.type === 'Buy');
    const cSell = consultation?.deals?.find((deal:any) => deal.type === 'Sell');
    const inquiry = consumer?.inquiries?.nodes?.length ? consumer.inquiries.nodes[0] : undefined;
    const details = inquiry?.details;
    const buyingPrice = (details?.buying?.price) || null;
    const sellingPrice = (details?.selling?.price) || null;
    const appLocation = (details && details.location && [details.location].map(({label}) => {
        const [city, state] = label.split(', ');
        return `${city}, ${state}`;
    })[0]) || null;

    function createBullets() {
        const bullets: string[] = [];

        if (deal.side !== 'Buy') {
            const price = cSell?.price ? `$${cSell.price.toLocaleString()}` : sellingPrice;
            if (price)
                bullets.push(`You would like to sell a home for ${price}`);
        }
        if (deal.side !== 'Sell') {
            bullets.push(`You would like to purchase a home in ${cBuy?.location?.cityState ?? appLocation}`);

            const price = cBuy?.minPrice && cBuy?.maxPrice ? `$${cBuy.minPrice.toLocaleString()} - $${cBuy.maxPrice.toLocaleString()}` : buyingPrice;
            if (price)
                bullets.push(`Your budget is ${price}`);

            const timeframe = (cBuy?.timeframe ?? details?.buying?.timeframe ?? '').toLowerCase();

            if (timeframe === 'just looking')
                bullets.push('You are currently just looking')
            else
                bullets.push(`You would like to start looking at homes ${timeframe}`);
        }

        return bullets;
    }

    let appt = consumer.engagebay.apptScheduledInfo?.replace(/.*- (.*), \d{4}$/, '$1');

    if (!appt?.match(/\d$/)) {
        const date = appt ? new Date(appt) : new Date();

        appt = date.toDateString().replace(/^(\S+)(.*) \d{4}.*/, '$1,$2');
    }

    const [appointment, setAppointment] = useState(appt);

    const updateAppointment: ChangeEventHandler<HTMLInputElement> = e => {
        setAppointment(e.target.value);
    }

    const [bullets, setBullets] = useState(() => createBullets());

    const addBullet = () => {
        setBullets(bullets => bullets.concat(''));
    }

    const updateBullet = (i: number) => (e:ChangeEvent<HTMLInputElement>) => {
        setBullets(_bullets => {
            const bullets = _bullets.slice();

            const value = e.target.value || '';

            if (!value.trim())
                return bullets.filter((bullet, index) => i !== index);

            bullets[i] = value;

            return bullets;
        })
    }

    const bulletsValid = 1 < bullets.filter(bullet => !!(bullet.trim())).length;
    const blurbsValid = inquiry?.providers && agents.every(data => {
        if (!data.provider?.id)
            return false;

        const blurbIndex = (inquiry.providers[data.provider.id])?.blurbIndex || 0;

        return blurbIndex < (data.provider?.info?.blurbs?.length || 0);
    });

    const [preview, setPreview] = useState(false);

    return (
        <Overlay>
            <Popup>
                <div>
                    <h3>Consultation Summary:</h3>
                    <Label style={{width:'100%',display:'flex'}}>Consultation Date: <input value={appointment} onChange={updateAppointment} style={{flexGrow:1,marginLeft:'.5em'}}/></Label>
                    {/*<TextArea label="Personalized Paragraph:" defaultValue={consumer.consultation?.personalizationParagraph}/>*/}
                    <ul>
                        {bullets.map((bullet, i) => (<li key={i}><input value={bullet} style={{width:'100%'}} onChange={updateBullet(i)}/></li>))}
                        <li onClick={addBullet} style={{cursor: 'pointer'}}>Add Bullet</li>
                    </ul>
                </div>
                <div>
                    {agents.map((data, i) => (
                        <Agent key={i} inquiry={inquiry} data={data}/>
                    ))}
                </div>
                <div>
                    <Label style={{width:'100%',display:'flex'}}>Signature: <input value={signature || viewer?.firstName} onChange={(e:any) => setSignature(e.target.value)} style={{flexGrow:1,marginLeft:'.5em'}}/></Label>
                </div>
                <Buttons>
                    <Button disabled={!(bulletsValid && blurbsValid)} onClick={() => setPreview(true)}>Preview Email</Button>
                    <Button onClick={close} variation="white">Cancel</Button>
                </Buttons>

                {preview && <EmailPreview appointment={appointment} bullets={bullets} close={() => setPreview(false)} agents={agents} signature={signature}/>}
            </Popup>
        </Overlay>
    )
}

interface EmailPreviewProps {
    appointment: string;
    bullets: string[];
    close: () => void;
    agents: PotentialProvider[];
    signature: string;
}

const EmailPreview: React.FC<EmailPreviewProps> = ({appointment, bullets, close, agents, signature}) => {
    const {viewer} = useViewer();

    const {consumerId} = useParams();
    const {data, refetch: refetchConsumer} = useQuery(ConsumerByIdQuery, {variables:{id: consumerId}});
    const {deal} = useContext(AgentFinderContext);
    const dealUniqueId = deal?.uniqueId;

    const admin = ((viewer?.firstName) || '');

    const params = {
        id: consumerId,
        dealUniqueId,
        admin: signature || admin,
        appointment,
        bullets: bullets.map(bullet => `<li>${bullet}</li>`).join(''),
        agents: agents.map(data => data.provider?.email).filter(email => !!email).join(',')
    }

    const queryString = Object.entries(params).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

    const [sendingEmail, setSendingEmail] = useState(false);

    const setPresentAgents = () => {
        setSendingEmail(true);

        const presented = agents.map(data => data.provider?.email).filter(email => !!email);

        const params: any = {
            consumerId,
            dealUniqueId,
            Agents_Presented: presented.join(', '),
            Agents_Presented_Date: new Date().toLocaleDateString()
        };

        if (deal.track === 'Member Leads')
            params.milestone = 'Connecting to Agent';

        const body = JSON.stringify(params);

        return fetch(`${api.endpoint}/eb-deal`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body
        })
            .then(() => refetchConsumer());
    }

    const presentAgents = () => {
        const email = data?.consumerById?.account?.contacts.nodes.find(contact => contact.type === 'email');

        if (!email?.value.address) {
            console.warn('missing email address');
            return;
        }

        setSendingEmail(true);

        const body = JSON.stringify({
            to: email.value.address,
            url: `${EMAIL_URL}?${queryString}`
        });

        fetch(`${api.endpoint}/send-email`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body
        })
            .then(setPresentAgents)
            .catch(e => console.error(e));
    }

    const onlySetPresentAgents = () => {
        if (window.confirm(`Are you sure you want to set the agents but NOT SEND the matching agents email to ${data?.consumerById?.account?.firstName} ${data?.consumerById?.account?.lastName}?`))
            setPresentAgents().then();
    }

    return (
        <Overlay>
            <Popup>
                <Email src={`${EMAIL_URL}?${queryString}`} />
                <Buttons>
                    <Button onClick={presentAgents} disabled={sendingEmail}>Send Email</Button>
                    <Button onClick={onlySetPresentAgents} disabled={sendingEmail}>Set Agents - No Email</Button>
                    <Button onClick={close} variation="white">Cancel</Button>
                </Buttons>
            </Popup>
        </Overlay>
    )
}

const Email = styled.iframe`
    border: none;
    height: 80vh;
    width: 700px;
`;