export function normalizeUUID(string:string) {
    return string.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5'); // format as UUID
}

export function findContact(contacts:any|null, type:string, field:string) {
    if (!(contacts && contacts.nodes))
        return null;

    const contact = contacts.nodes.find((contact:any) => !!contact && contact.type === type);

    if (!contact)
        return null;

    return contact.value[field];
}

export function isDirty(original:any, updated:any) {
    const originalFields = Object.keys(original||{});
    const updatedFields = Object.keys(updated||{});

    if (originalFields.length !== updatedFields.length)
        return true;

    for (let i = originalFields.length - 1; i >= 0; i--) {
        const k = originalFields[i];

        if (original[k] !== updated[k])
            return true;
    }

    return false;
}