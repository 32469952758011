import {useEffect} from 'react';


import useViewer from "../../lib/use-viewer";
import {apolloClient} from "../../config";
import {
    GetNextProviderTransactionQuery, UpdateProviderTransactionByIdMutation,
    UpdateTransactionLocationLookupFailedMutation,
    UpdateTransactionLocationMutation
} from "../../gql/UpdateTransactionLocation";

const LOCAL_STORAGE_KEY = 'LAST_GEOCODE_REQUEST';
const LOCAL_STORAGE_ADDRESS_KEY = 'GEOCODE_ADDRESS';

window.localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());
window.localStorage.removeItem(LOCAL_STORAGE_ADDRESS_KEY);

const started = Date.now();

export function BackgroundWorker() {
    const {viewer} = useViewer();

    useEffect(() => {
        if (!viewer)
            return;

        let handle: number = 0;

        const geocoder = new window.google.maps.Geocoder();

        const run = () => {
            const lastRequest = Number(window.localStorage.getItem(LOCAL_STORAGE_KEY) || Date.now());
            const elapsed = Date.now() - lastRequest;
            if (elapsed < 2000) {
                handle = window.setTimeout(run, 2500 - elapsed + Math.random() * 1000);
                return;
            }

            window.localStorage.setItem(LOCAL_STORAGE_KEY, Date.now().toString());

            apolloClient
                .query({
                    query: GetNextProviderTransactionQuery,
                    variables: {
                        t: Date.now().toString(36)
                    }
                })
                .then((result) => {
                    const t = result.data.getNextProviderTransaction;

                    if (!t)
                        return;

                    const {id, address1, city, state, zip} = t;

                    const address = `${address1}, ${city}, ${state}${zip ? ` ${zip}` : ''}`;

                    if (address === window.localStorage.getItem(LOCAL_STORAGE_ADDRESS_KEY)) {
                        console.warn(`looks like we're already looking up ${address}!`);
                        handle = window.setTimeout(run, 2500);
                        return;
                    }

                    window.localStorage.setItem(LOCAL_STORAGE_ADDRESS_KEY, address);

                    // console.log(`asking google for location of ${address}`);

                    // find lat/lon and create new inquiryAddress
                    geocoder.geocode({address}, (results: any, status: any) => {
                        // console.log({results, status});

                        if (status === "OVER_QUERY_LIMIT") {
                            console.warn(`stopping due to ${status} after ${((Date.now() - started)/60000).toFixed(0)} minutes`);

                            handle = window.setTimeout(run, 5*60*1000); // retry after 5 minutes

                            window.localStorage.removeItem(LOCAL_STORAGE_ADDRESS_KEY);

                            return;
                        }

                        const location = results && 0 < results.length && results[0].geometry?.location

                        const latitude = location?.lat();
                        const longitude = location?.lng();

                        let promise: Promise<any>;

                        if (latitude && longitude) {
                            promise = apolloClient.mutate({
                                mutation: UpdateTransactionLocationMutation,
                                variables: {
                                    input: {
                                        id,
                                        latitude,
                                        longitude
                                    }
                                }
                            });

                            const postalCode = results[0].address_components?.find((row: any) => row.types.includes('postal_code'))?.short_name;

                            if (postalCode && postalCode !== zip) {
                                promise = promise.then(() => {
                                    return apolloClient.mutate({
                                        mutation: UpdateProviderTransactionByIdMutation,
                                        variables: {
                                            input: {
                                                id,
                                                patch: {
                                                    zip: postalCode
                                                }
                                            }
                                        }
                                    })
                                });
                            }
                        }
                        else {
                            console.log({status, results});

                            const autocomplete = new window.google.maps.places.AutocompleteService();

                            promise = apolloClient.mutate({
                                mutation: UpdateTransactionLocationLookupFailedMutation,
                                variables: {
                                    input: {
                                        id
                                    }
                                }
                            });

                            promise = promise.then(() => {
                                autocomplete.getPlacePredictions({
                                    input: address,
                                    types: ['address']
                                }, (
                                    predictions: any /*google.maps.places.QueryAutocompletePrediction[] | null*/,
                                    status: any /*google.maps.places.PlacesServiceStatus*/
                                ) => {
                                    if (status === 'OK') {
                                        console.log(`transaction address: ${address}`);
                                        const addresses = predictions.map((prediction: any) => prediction.description);
                                        console.log(`predictions: ${addresses.join(' | ')}`);
                                        // console.log({status, predictions});
                                    }
                                });
                            });
                        }

                        if (promise) {
                            promise.then(() => {
                                handle = window.setTimeout(run, 2500);

                                window.localStorage.removeItem(LOCAL_STORAGE_ADDRESS_KEY);
                            });
                        }
                    });
                });
        };

        run();

        return () => {
            if (handle)
                window.clearTimeout(handle);
        }
    }, [viewer]);

    return null;
}