import {graphql} from './types';

export const fragments = {
    avatar: graphql(`
        fragment ViewerAvatar on Account {
            avatar {
                nodeId
                url
                meta
            }
        }
    `),

    contacts: graphql(`
        fragment ViewerContacts on Account {
            contacts {
                nodes {
                    id
                    nodeId
                    type
                    value
                    verifiedAt
                    verificationAttempts
                }
            }
        }
    `)
};

export default graphql(`
    query Viewer {
      viewer {
        nodeId
        id
        admin
        firstName
        lastName
        
        ...ViewerAvatar
        ...ViewerContacts
      }
    }
`);