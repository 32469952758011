import React from 'react';
import {Route, Routes} from 'react-router-dom'

import Login from './Login';
import TokenLogin from './TokenLogin';

const _ = () => {
    if (localStorage.getItem('jwt:admin')) {
        document.location = '/dashboard';
        return null;
    }

    return (
        <Routes>
            <Route index element={<Login/>}/>
            <Route path=":token" element={<TokenLogin/>}/>
        </Routes>
    );
};

export default _;