import {graphql} from './types';

export const fragments = {
    provider: graphql(`
        fragment ProviderData on Provider {
          nodeId
          id
          accountId
          account {
            nodeId
	    id
            firstName
            lastName
            suppressMessages
            contacts {
              nodes {
                nodeId
                type
                value
              }
            }
            campaign
            deletedAt
          }
          signed
          engagebay
          type
          name
          slug
          title
          gender
          phone
          email
          image {
                url
            }
          info
            transactionHistory
            setupComplete
            approvalRequested
            approved
            public
            minPrice
            maxPrice
          createdAt
          areaCenter
          status
          docusignStatus
          docusignSigned
          docusignExpiration
          profileComplete
          signed
          bizDev
          
          reviews {
            nodes {
              id
              nodeId
              consumer {
                account {
                  firstName
                  lastName
                }
              }
              reviewer
              rating
              ratingCommunication
              ratingResponsiveness 
              ratingLocalKnowledge
              wouldUseAgain
              comment
            }
          }

          rating
          ratingCommunication
          ratingResponsiveness 
          ratingLocalKnowledge

          deletedAt
        }
    `)
};

export const providerQueryDocument = graphql(`
    query Provider($nodeId: ID!) {
        provider(nodeId: $nodeId) {
            ...ProviderData
        }
    }
`);

export const providerByIdQueryDocument = graphql(`
    query ProviderById($id: UUID!) {
        providerById(id: $id) {
            ...ProviderData
        }
    }
`);

export const providerBySlugQueryDocument = graphql(`
    query ProviderBySlug($slug: Slug!) {
        providerBySlug(slug: $slug) {
            ...ProviderData
        }
    }
`);

export const updateProviderMutationDocument = graphql(`
    mutation UpdateProvider($input: UpdateProviderInput!) {
        updateProvider(input: $input) {
            provider {
                nodeId
                id
                name
                title
                slug
                phone
                email
                image {
                    url
                }
                info
                transactionHistory
                setupComplete
                approvalRequested
                approved
                public
                minPrice
                maxPrice
                docusignStatus
            }
        }
    }
`);

export const providersByLocationQueryDocument = graphql(`
    query ProvidersByLocation($locationId: String!) {
        providersByLocation(locationId: $locationId) {
            nodes {
                ...ProviderData
                cities
                engagebay
                info
                account {
		    id
                    contacts {
                      nodes {
                        nodeId
                        type
                        value
                        verifiedAt
                      }
                    }
                }
            }
        }
    }
`);

export const providersByCityStateQueryDocument = graphql(`
    query ProvidersByCityState($city: String!, $state: String!, $miles: BigFloat) {
        providersByCityState(city: $city, state: $state, miles: $miles) {
            nodes {
                id
                name
                title
                phone
                email
                image {
                    url
                }
                info
                transactionHistory
                profileComplete
                geoJson
                engagebay
                status
                approvalRequested
                public
                approved
                signed
                bizDev
                account {
                    id
                    firstName
                    lastName
                }
                docusignStatus
            }
        }
    }
`);

export const providersNearestQueryDocument = graphql(`
    query ProvidersNearest($latitude: BigFloat!, $longitude: BigFloat!) {
        providersNearest(latitude: $latitude, longitude: $longitude) {
            nodes {
                id
                name
                title
                phone
                email
                image {
                    url
                }
                info
                transactionHistory
                profileComplete
                geoJson
                engagebay
                status
                approvalRequested
                public
                approved
                signed
                bizDev
                account {
                    id
                    firstName
                    lastName
               }
                docusignStatus
            }
        }
    }
`);

export const providerTransactionLocationsQueryDocument = graphql(`
    query ProviderTransactionLocations($id: UUID!) {
        providerById(id: $id) {
            transactionLocations
        }
    }
`);

export const providersByTransactionLocationQueryDocument = graphql(`
    query ProvidersByTransactionLocation($city: String, $state: String, $zip: String) {
        providersByTransactionLocation(city: $city, state: $state, zip: $zip) {
            nodes {
              provider {
                nodeId
                id
                name
                location
                status
                syntheticStatus
                docusignStatus
                docusignSigned
                signed
                public
                approved
                engagebay
                info
                bizDev
                transactions {
                  nodes {
                    nodeId
                    id
                    address1
                    address2
                    city
                    state
                    zip
                  }
                }
                account {
                    id
                    firstName
                    lastName
                    campaign
                  }
              }
              count
            }
        }
    }
`);

export const createReviewMutationDocument = graphql(`
    mutation CreateReview($input: CreateReviewInput!) {
        createReview(input: $input) {
            review {
                provider {
                    ...ProviderData
                }
            }
        }
    }
`);