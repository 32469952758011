import React from "react";
import {Route, Routes} from "react-router-dom";

import Consumers from './Consumers';
import Points from './Points';

const _ = () => {
    return (
        <Routes>
            <Route index element={<Consumers/>}/>
            <Route path={`points/:consumerId`} element={<Points/>}/>
        </Routes>

    )
};

export default _;