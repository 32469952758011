import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";

import {Marker} from "./AgentFinder";
import {PotentialProvider} from "../../gql/types/graphql";
import {getLatitudeLongitude} from "../../config";

const StyledMap = styled.div`
    width: 100%;
    height: 100%;
`;

const w:any = window;

interface Props {
    markers: Array<Marker>;
    transactionMarkers: Array<Marker>;
    providers: Array<PotentialProvider>|null;
    cityGeoJSON: any;
    city: string;
    state: string;
}

const MarkerImage = (marker: Marker) => {
    if (marker.type === 'transaction') {
        return {
            path: 'M0, 128 a 128,128 0 1,0 256,0 a 128,128 0 1,0 -256,0',
            anchor: new google.maps.Point(128, 128),
            fillOpacity: 1,
            fillColor: marker.color,
            strokeWeight: 1,
            strokeColor: 'black',
            scale: .075
        }
    }

    const homeSVG = 'M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z';
    const signSVG = 'M496 64H128V16c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16v48H16C7.2 64 0 71.2 0 80v32c0 8.8 7.2 16 16 16h48v368c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V128h368c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM160 384h320V160H160v224z';

    return {  // https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel
        path: marker.type === 'house' ? homeSVG : signSVG,
        anchor: marker.type === 'house' ? new google.maps.Point(288,256) : new google.maps.Point(256,256),
        fillOpacity: 1,
        fillColor: marker.type === 'house' ? 'green' : 'red',
        strokeWeight: 2,
        strokeColor: "white",
        scale: .075
    };
}

const Map:React.FC<Props> = ({markers, transactionMarkers, providers, cityGeoJSON, city, state}) => {
    const [map, setMap] = useState<any>(null);

    useEffect(() => {
        if (!map)
            return;

        const bounds = new w.google.maps.LatLngBounds();

        markers.forEach(marker => {
            bounds.extend(marker.position);
        });

        if (markers.length === 1) {
            map.setCenter(markers[0].position);
            map.setZoom(8);
        }
        else if (1 < markers.length) {
            map.fitBounds(bounds);
        }
    }, [markers, map]);

    const [/*infoWindow*/, setInfoWindow] = useState<google.maps.InfoWindow|null>(null)

    useEffect(() => {
        if (!map)
            return;

        const center = {
            lat: map.getCenter().lat(),
            lng: map.getCenter().lng()
        };

        const distanceService = new google.maps.DistanceMatrixService();

        const mapMarkers: any[] = [...markers, ...transactionMarkers.filter(marker => distance(center, marker.position) <= 100)]
            .map(marker => {
            const m = new w.google.maps.Marker({
                position: marker.position,
                label: marker.label,
                map,
                title: marker.title,
                icon: MarkerImage(marker)
            });

            if (marker.transaction) {
                const t = marker.transaction;

                const date = new Date(t.closeDate).toDateString()
                    .replace(/^\S+\s+/, '')
                    .replace(/(\s\d{4})$/, ',$1');

                const txnContent = `
    <div style="display:grid;grid-template-columns:auto 1fr;gap:8px;">
        <div style="text-align:right;">Transaction Date:</div><div>${date}</div>
        <div style="text-align:right;">Price:</div><div style="font-weight: bold;">$${t.closePrice.toLocaleString()}</div>
        <div style="text-align:right;">Address:</div><div>${t.address1}${t.address2 ? `<br/>${t.address2}` : ''}<br/>${t.city}, ${t.state} ${t.zip}</div>
    </div>
    `;

                m.addListener('click', () => {

                    let content = `
${txnContent}
`;

                    distanceService.getDistanceMatrix({
                        origins: [marker.position],
                        destinations: 0 < markers.length ? markers.map(marker => marker.position) : [`${city}, ${state}, USA`],
                        travelMode: google.maps.TravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.IMPERIAL
                    }, response => {
                        response?.rows.forEach(row => {
                            const results = row.elements;
                            results.sort((a, b) => a.duration.value - b.duration.value);
                            results.forEach(({status, distance, duration}, i) => {
                                if (status !== 'OK')
                                    return;

                                const address = 0 < markers.length ? markers[i].title?.split(' | ')[1].split(',')[0] : `${city}, ${state}`;

                                content += `<div style="margin-top: 16px;">${address}: ${distance.text} - <strong>${duration.text}</strong></div>`;
                            })
                        });

                        setInfoWindow(previous => {
                            if (previous) {
                                try {
                                    previous.close();
                                }
                                catch (e) {
                                    console.error(e);
                                }
                            }

                            const info = new google.maps.InfoWindow({content});

                            info.open({
                                anchor: m,
                                map,
                                shouldFocus: false
                            });

                            return info;
                        });
                    });
                });
            }

            return m;
        });

        return () => {
            mapMarkers.forEach(marker => marker.setMap(null));
        }
    }, [markers, transactionMarkers, map, city, state]);

    useEffect(() => {
        if (!(providers && map))
            return;

        // clear existing areas
        map.data.forEach((feature: any) => map.data.remove(feature));

        if (!(markers && 0 < markers.length) && cityGeoJSON) {
            const geoJSON = { ...cityGeoJSON, features: cityGeoJSON.features.map((feature: any) => {
                    return {...feature, properties: {...feature.properties, type: 'inquiry'}}
                })}
            map.data.addGeoJson(geoJSON, {idPropertyName: 'cityGeoJSON'});
        }

        providers.forEach(potentialProvider => {
            if (potentialProvider?.provider?.geoJson)
                map.data.addGeoJson(potentialProvider.provider.geoJson);
        });
    }, [providers, map, markers, cityGeoJSON]);

    useEffect(() => {
        if (!map || (markers && 0 < markers.length) || !cityGeoJSON)
            return;

        const bounds = new w.google.maps.LatLngBounds();
        // @ts-ignore
        map.data.forEach(function (feature: any) {
            const geometry = feature.getGeometry();

            if (geometry)
                processPoints(feature.getGeometry(), bounds.extend, bounds);
        });
        // @ts-ignore
        map.fitBounds(bounds);
    }, [markers, cityGeoJSON, map])

    const attachMap = useCallback((element: any) => {
        if (!element)
            return;

        if (map) {
            map.data.setStyle((feature: any) => {
                const color = (feature.getProperty('type') === 'inquiry') ? 'red' : 'blue';

                return {
                    fillColor: color,
                    fillOpacity: .5,
                    strokeWeight: 2,
                    strokeColor: color,
                    strokeOpacity: 0,
                }
            });

            return;
        }

        const createMap = (latitude?:number, longitude?:number) => {
            const map = new w.google.maps.Map(element, {
                center: {lat: latitude || 33.6741316, lng: longitude || -111.9988795},
                zoom: 8,
                mapTypeControlOptions: {
                    mapTypeIds: []
                },
                streetViewControl: false,
                // clickable: true
            });

            setMap(map);
        }

        getLatitudeLongitude()
            .then(({latitude, longitude}) => {
                createMap(latitude, longitude);
            })
            .catch(e => console.error(e));
    }, [map]);

    return (
        <StyledMap ref={attachMap}/>
    )
};

export default Map;

function processPoints(geometry:any, callback:any, thisArg:any) {
    if (geometry instanceof w.google.maps.LatLng) {
        callback.call(thisArg, geometry);
    } else if (geometry instanceof w.google.maps.Data.Point) {
        callback.call(thisArg, geometry.get());
    } else {
        geometry.getArray().forEach(function (g:any) {
            processPoints(g, callback, thisArg);
        });
    }
}



//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::                                                                         :::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points). It is being used to calculate     :::
//:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
//:::                                                                         :::
//:::  Definitions:                                                           :::
//:::    South latitudes are negative, east longitudes are positive           :::
//:::                                                                         :::
//:::  Passed to function:                                                    :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::                                                                         :::
//:::  Worldwide cities and other features databases with latitude longitude  :::
//:::  are available at https://www.geodatasource.com                         :::
//:::                                                                         :::
//:::  For enquiries, please contact sales@geodatasource.com                  :::
//:::                                                                         :::
//:::  Official Web site: https://www.geodatasource.com                       :::
//:::                                                                         :::
//:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
//:::                                                                         :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
interface LatLng { lat: number, lng: number }

export function distance(p1: LatLng, p2: LatLng) {// lat1: number, lon1: number, lat2: number, lon2: number) {
    if ((p1.lat === p2.lat) && (p1.lng === p2.lng)) {
        return 0;
    }
    else {
        const radlat1 = Math.PI * p1.lat/180;
        const radlat2 = Math.PI * p2.lat/180;
        const theta = p1.lng-p2.lng;
        const radtheta = Math.PI * theta/180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

        if (dist > 1) {
            dist = 1;
        }

        dist = Math.acos(dist);
        dist = dist * 180/Math.PI;
        dist = dist * 60 * 1.1515;

        return dist;
    }
}