import React, {useEffect} from 'react';
import Page from './Page';
import Spinner from './Spinner';

import {useRedirectToLogin} from "../lib/authentication";
import useViewer from "../lib/use-viewer";

const AuthenticatedPage = (props:any) => {
    const {loading, viewer, error} = useViewer();
    const redirectToLogin = useRedirectToLogin();

    useEffect(() => {
        if (loading)
            return;

        if (error || !(viewer && viewer.admin)) {
            console.log(`redirecting to login`, {loading, viewer, error});
            redirectToLogin();
        }
    }, [loading, viewer, error, redirectToLogin]);

    let showSpinner = false;

    if (typeof window === 'undefined' || loading) {
        // not ready yet so show a spinner
        showSpinner = true;
    }
    else if (error || !viewer) {
        // not authenticated so redirect
        showSpinner = true;
    }
    else if (viewer) {
        if (!viewer.admin) {
            showSpinner = true;
        }
    }

    if (showSpinner)
        return <Page key="spinner" fullWidth={true}><Spinner/></Page>;
    else
        return <Page key="authenticated-page" {...props}/>;
};

export default AuthenticatedPage;