import React from 'react';
import {useQuery} from "@apollo/client";
import {providerByIdQueryDocument, fragments} from "../../gql/Provider";
import {api, JWT_KEY} from "../../config";
import {formatPrice, getPriceInfo} from "./AgentFinder";
import {useFragment} from "../../gql/types";

interface TransactionInfoProps {
    providerId: string | null | undefined;
}

const NEED_TXN_HISTORY = 'need txn history';

export const TransactionInfo: React.FC<TransactionInfoProps> = ({ providerId }) => {
    const {data, refetch} = useQuery(providerByIdQueryDocument, { variables: { id: providerId } });

    const provider = useFragment(fragments.provider, data?.providerById);

    const priceInfo = getPriceInfo(provider?.transactionHistory);

    const hasNeedTxnHistoryTag = !!provider?.engagebay?.tags?.includes(NEED_TXN_HISTORY)

    // if (provider?.name.match(/Mia/))
    //     console.log({hasNeedTxnHistoryTag, tags: provider?.engagebay.tags});

    const setTxnTag = hasNeedTxnHistoryTag ? undefined : (e:any) => {
        e.preventDefault();

        if (!provider?.email)
            return;

        const body = JSON.stringify({
            email: provider.email,
            tag: NEED_TXN_HISTORY
        });


        fetch(`${api.endpoint}/eb-tag`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body
        })
            .then(() => {
                window.setTimeout(refetch, 10000);
            });
    }

    if (priceInfo.count)
        return <div>{formatPrice(priceInfo.average)}/{formatPrice(priceInfo.max)}, {priceInfo.count} txns</div>

    if (hasNeedTxnHistoryTag)
        return <div style={{color: '#ccc'}}>add "{NEED_TXN_HISTORY}" tag</div>;

    return <div><a href={"add-tag"} onClick={setTxnTag}>add "{NEED_TXN_HISTORY}" tag</a></div>;
}
