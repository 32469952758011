import React from 'react';
import styled from "styled-components";

import AuthenticatedPage from "../../components/AuthenticatedPage";

const Heading = styled.h1`
  text-align: center;
  padding: 2rem 0;
`;

const Dashboard = () => {
    return (
        <AuthenticatedPage title="Dashboard" fullWidth={true} padding="0 0 1rem" background="#ccc">
            <Heading>Dashboard</Heading>
        </AuthenticatedPage>
    )
};

export default Dashboard;