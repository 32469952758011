import {graphql} from './types';

export default graphql(`
    query ConsumersList($options: JSON) {
      allConsumers(options: $options) {
          nodes {
            ...ConsumerData
          }
      }
    }
`);

export const consumersByLocationQueryDocument = graphql(`
    query ConsumersByLocation($locationId: String!) {
        consumersByLocation(locationId: $locationId) {
            nodes {
              id
              vendors
              createdAt
              engagebay
              account {
                firstName
                lastName
                
                contacts(condition: {type:"email"}) {
                  nodes {
                    value
                  }
                }
              }
              inquiries {
                nodes {
                    details
                }
              }
            }
        }
    }
`);
