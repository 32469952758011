import {graphql} from './types';

export const inquiryByIdQueryDocument = graphql(`
    query InquiryById($id: UUID!) {
        inquiryById(id: $id) {
            nodeId
            id
            details
            detailsConfirmed
            providers
            potentialProviders {
                nodes {
                    distance
                    provider {
                        id
                        name
                        title
                        phone
                        email
                        image {
                            url
                        }
                        info
                        transactionHistory
                        profileComplete
                        geoJson
                        engagebay
                        status
                        approvalRequested
                        public
                        approved
                        signed
                        account {
                            id
                            firstName
                            lastName
                       }
                    }
                }
            }
            flags
            inquiryAddresses {
                nodes {
                  nodeId
                  id
                  address
                  price
                  latitude
                  longitude
                }
            }
        }
    }
`);

export const updateInquiryMutationDocument = graphql(`
    mutation UpdateInquiry($input: UpdateInquiryInput!) {
        updateInquiry(input: $input) {
            inquiry {
              nodeId
              id
              providers
              details
              flags
            }
        }
    }
`);

export const addInquiryAddressMutationDocument = graphql(`
    mutation AddInquiryAddress($input: AddInquiryAddressInput!) {
        addInquiryAddress(input: $input) {
            inquiry {
                nodeId
                inquiryAddresses {
                    nodes {
                        nodeId
                        id
                        address
                        price
                        latitude
                        longitude
                    }
                }
            }
        }
    }
`);

export const updateInquiryAddressMutationDocument = graphql(`
    mutation UpdateInquiryAddress($input: UpdateInquiryAddressInput!) {
        updateInquiryAddress(input: $input) {
            inquiryAddress {
                nodeId
                id
                address
                price
                latitude
                longitude
                ebDealUniqueId
                inquiry {
                    nodeId
                    potentialProviders {
                        nodes {
                            distance
                            provider {
                                id
                                name
                                title
                                phone
                                email
                                image {
                                    url
                                }
                                info
                                transactionHistory
                                profileComplete
                                geoJson
                                engagebay
                                status
                                approvalRequested
                                public
                                approved
                                signed
                                account {
                                    id
                                    firstName
                                    lastName
                               }
                            }
                        }
                    }
                }
            }
        }
    }
`);
