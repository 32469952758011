import {useQuery} from "@apollo/client";

import viewerQueryDocument from '../gql/Viewer';
import {useEffect} from "react";
import {getJWT, useClearStorage} from "./authentication";

export default function useViewer() {
    const {data, loading, error} = useQuery(viewerQueryDocument);
    const viewer = data && data.viewer;
    const clearStorage = useClearStorage();

    const jwt = getJWT();

    useEffect(() => {
        if (loading || error)
            return;

        if (jwt && !viewer)
            clearStorage().then();
    }, [viewer, loading, error, jwt, clearStorage]);

    return {loading, viewer, error};
}
