import gql from 'graphql-tag';

export default gql`
    query PointsLedgers {
        pointsLedgers {
            nodes {
                nodeId
                id
                entryType
                quantity
                note
                data
                createdAt
                account {
                    firstName
                    lastName
                    nodeId
                    id
                    consumer {
                        nodeId
                        id
                    }
                    contacts {
                        nodes {
                            type
                            value
                        }
                    }
                }
                accountByCreatedBy {
                    firstName
                    lastName
                    nodeId
                    id
                }
            }
        }
    }
`;