import React, {useEffect, useRef, useState} from 'react';

import {geoLookup} from '../../config';
import AutoComplete, {Option} from "./AutoComplete";

import autocompleteLocationQueryDocument from '../../gql/AutocompleteLocation';
import {useLazyQuery} from "@apollo/client";

export interface LocationOption extends Option {
}

interface ComponentProps {
    label?: string,
    grow?: boolean,
    placeholder?: string,
    onSelect: (location:LocationOption|null) => boolean|void,
    defaultValue?: LocationOption,
    [key:string]: any
}

const AutoCompleteLocation = ({defaultValue, ...props}:ComponentProps) => {
    const [input, setInput] = useState(defaultValue?.label || '');
    const [zipCode, setZipCode] = useState((defaultValue?.id) || '');
    const callbackRef = useRef<any>(null);
    const [findLocations, {data}] = useLazyQuery(autocompleteLocationQueryDocument);

    useEffect(() => {
        geoLookup()
            .then(({zip}:any) => setZipCode(zip));
    }, []);

    useEffect(() => {
        const locations = (data && data.autocompleteLocation) || [];

        if (callbackRef.current) {
            callbackRef.current(locations
                .slice(0, 5)
                .map(({zip, city, state}: any) => ({id: zip, label: input.match(/^\d+$/) ? zip : `${city}, ${state}`}))
            );
        }
    }, [data, input]);

    useEffect(() => {
        if (!input)
            return;

        findLocations({
            variables: {
                search: input.trim(),
                zipCode: zipCode || '66952'
            }
        });
    }, [findLocations, input, zipCode]);

    const onInputChange = (input:string, callback:(options:LocationOption[]) => void) => {
        setInput(input);

        if (!input)
            return Promise.resolve([]);

        callbackRef.current = callback;
    };

    return (
        <AutoComplete<LocationOption> onInputChange={onInputChange} defaultValue={defaultValue} {...props}/>
    )
};

export default AutoCompleteLocation;