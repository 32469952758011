import React, {MouseEventHandler} from 'react';

import AuthenticatedPage from "../../components/AuthenticatedPage";
import {COLUMNS, ConsumersList, Flags, Links} from "../Consumers/Consumers";
import {useAllConsumerDeals} from "../../lib/useAllConsumers";
import {FaEnvelope} from "react-icons/fa";


const memberLeadsTrack = (c:any) => {
    if (!c)
        return false;

    return (
        c.tracks.some((track:string) => track === 'Member Leads')
        // c.milestones.some((milestone:string) => MILESTONES.includes(milestone)) ||
        // c.buckets.some((bucket:string) => BUCKETS.includes(bucket))
    );
};

const PIPELINE_COLUMNS = COLUMNS.filter(column => !column.label || !['AmEx', 'Progress', 'Email'].includes(column.label)).map((column, i) => {
    if (i === 2 && !column.label)
        return {width: '205px', render: (data: any) => {
                const id = data.deal?.id;

                if (!id)
                    return '';

                const inquiry = data.inquiry;

                return (
                    <Links>
                        <a href={`https://joinrbn.engagebay.com/home#deal/${id}`} target="engagebay">EB</a>
                        {inquiry && <Flags inquiry={inquiry} dealId={id} showingDog={data.showingDog === 'Y'}/>}
                    </Links>
                );
            }
        }

    if (column.label === 'Email')
        return {label: 'Email', width: '.25fr', field: 'email', type: 'text', sort: true, render: (data:any) => {
                const copyToClipboard: MouseEventHandler<HTMLAnchorElement> = e => {
                    e.preventDefault();

                    navigator.clipboard.writeText(data.email).then();
                }

                return <a href={`mailto:${data.email}`} onClick={copyToClipboard}><FaEnvelope/></a>
            }};

    return column;
});
PIPELINE_COLUMNS.splice(6, 0, { label: 'Invited', field: 'invited', width: '65px', sort: true, type: 'select'});
PIPELINE_COLUMNS.push({label: 'Connect By', width: '100px', field: 'connectBy', align: 'right', sort: 'connectByDate'});

const DEFAULT_SORT = {
    column:PIPELINE_COLUMNS.length - 2,
    descending: true
};


const Pipeline = () => {
    const consumers = useAllConsumerDeals();

    return (
        <AuthenticatedPage title="Pipeline" fullWidth={true} padding="0 0 1rem">
            <ConsumersList consumers={consumers} filter={memberLeadsTrack} columns={PIPELINE_COLUMNS} defaultSort={DEFAULT_SORT}/>
        </AuthenticatedPage>
    );
};

export default Pipeline;
