import loginAs from './login-as';

export default function useLoginAs() {
    return (account:any) => {
        return async (e: any) => {
            if (e.preventDefault)
                e.preventDefault();

            if (window.confirm(`Login as ${account.firstName} ${account.lastName}?`)) {
                await loginAs(account);
            }
        };
    }
}