import {useLogout} from "../lib/authentication";

const Logout = () => {
    const logout = useLogout();

    logout();

    document.location = '/';

    return null;
};

export default Logout;