import AuthenticatedPage from "../../components/AuthenticatedPage";
import {useQuery} from "@apollo/client";
import PointsLedgersQueryDocument from "../../gql/PointsLedgers";
import React from "react";
import PointsLedger from "./PointsLedger";

export default function RewardsLog() {
    const { data } = useQuery(PointsLedgersQueryDocument);

    return (
        <AuthenticatedPage>
            <PointsLedger pointsLedger={(data?.pointsLedgers?.nodes || []).slice()}/>
        </AuthenticatedPage>
    );
}
