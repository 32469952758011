import {graphql} from './types';

export default graphql(`
query AccountById($id: UUID!) {
    accountById(id: $id) {
        nodeId
        id
        firstName
        lastName
        contacts {
            nodes {
                nodeId
                id
                type
                value
            }
        }
    }
}
`);