import {graphql} from './types';

export default graphql(`
    query AgentsByCityState($city: String!, $state: String!) {
        agentsByCityState(city: $city, state: $state) {
            nodes {
                firstName
                lastName
                email
                companyName
                companyAddress
                city
                state
                zip
                county
                companyPhone
                phone
            }
        }
    }
`);