import gql from "graphql-tag";

export default gql`
    mutation PointsLedgerAdjust($input: PointsLedgerAdjustInput!) {
        pointsLedgerAdjust(input: $input) {
            account {
                nodeId
                id
                pointsAvailable
                pointsLedger(orderBy: CREATED_AT_DESC) {
                    nodes {
                        nodeId
                        id
                        entryType
                        quantity
                        note
                        createdAt
                        accountByCreatedBy {
                          firstName
                          lastName
                          nodeId
                          id
                        }
                    }
                }
            }
        }
    }
`;