import React, {ChangeEventHandler, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

import AuthenticatedPage from '../../components/AuthenticatedPage';
import useLoginAs from "../../lib/use-login-as";
import Map, {distance} from "./Map";
import {useParams} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import consumerByIdQueryDocument from '../../gql/Consumer';
import Spinner from "../../components/Spinner";
import {findContact} from "../../lib/misc";
import {FaSms} from "react-icons/fa";
import { updateInquiryMutationDocument } from '../../gql/Inquiry';
import Addresses from "./Addresses";
import cityGeoJSONQueryDocument from "../../gql/CityGeoJSON";
import AutoCompleteLocation, {LocationOption} from "../../components/form/AutoCompleteLocation";
import {api, apolloClient, JWT_KEY} from "../../config";

import {
    dealPotentialProvidersQueryDocument,
    cityStatePotentialProvidersQueryDocument,
    zipPotentialProvidersQueryDocument,
    PotentialProviderFragment
} from "../../gql/PotentialProviders";
import {Select} from "../../components/form";
import useRedirect from "../../lib/use-redirect";
import zipGeoJSONQueryDocument from "../../gql/ZipGeoJSON";
import {SmsPopup} from "./SmsPopup";
import {Provider} from "./Provider";
import {PresentAgents} from "./PresentAgents";
import Consultation from "./Consultation";
import {
    Consumer,
    Inquiry,
    PotentialProvider, ProviderTransaction
} from "../../gql/types/graphql";
import {useFragment} from "../../gql/types";

const StyledTextArea = styled.textarea`
    border: 1px solid #666;
`;

interface SearchLinkProps {
    term: string;
    children?: any;
    className?: string;
    style?: any
}

export const SearchLink:React.FC<SearchLinkProps> = ({term, children, className, style}) => (
    <a
        href={`https://www.google.com/search?q=${encodeURIComponent(term)}`}
        target="target-address"
        rel="noopener noreferrer"
        className={className}
        style={style}
    >{children || term}</a>
);

const ConsumerInfo = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    justify-content: center;
    grid-column-gap: 1rem;
    background: #fff;
    font-size: 115%;
    padding-bottom: 1rem;
    
    & > textarea {
        grid-column: 1 / -1;
    }
`;

export interface Marker {
    position: {
        lat: number,
        lng: number
    },
    label?: string,
    type: 'house'|'sign'|'city'|'transaction',
    color?: string,
    title?: string,
    transaction?: ProviderTransaction
}

interface ProviderFilterResult {
    matchingProviders: Array<PotentialProvider>,
    potentialProviders: Array<PotentialProvider>
}

const Results = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    min-height: 80vh;
    background: #fff;
`;

const ScrollBox = styled.div`
    position: relative;
    border-top: 1px solid #ccc;
    overflow-y: auto;
    overflow-x: hidden;
    width: 350px;
    max-height: 80vh;
    
    h2 {
        text-align: center;
        margin: .5rem 0;
        font-weight: bold;
        color: #666;
        position: relative;
    }
`

const ProviderList = styled.div`
`;

export function formatPrice(price:number) {
    return `$${formatPoints(price)}`;
}

export function formatPoints(price:number) {
    if (1000000 <= price)
        return `${Number(price/1000000).toFixed(2).replace(/0+$/, '').replace(/\.$/, '')}m`;
    else
        return `${Number(price/1000).toFixed(0)}k`;
}

type Transactions = ProviderTransaction[] | undefined;

export function getPriceInfo(transactions?: Transactions) {
    const priceInfo = (transactions || []).reduce((map:any, transaction:any) => {
        const price = transaction.closePrice || transaction.soldPrice;

        if (!price || price < 100000)
            return map;

        map.min = Math.min(map.min || Number.MAX_SAFE_INTEGER, price);
        map.max = Math.max(map.max, price);
        map.total += price;
        map.count++;

        return map;
    }, {max: 0, min: 0, total: 0, count: 0});

    if (priceInfo.count)
        priceInfo.average = priceInfo.total / priceInfo.count;

    return priceInfo;
}

const InfoBottom = styled.div`
    grid-column: span 6;    
    font-size: 90%;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    & > :nth-child(2) {
        margin-left: 2rem;
    }
`;

const LocationHeader = styled.div`
    position: relative;
    z-index: 65535;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    
    label {
        margin: 0 0.5rem;
    }
`;

const NoMatchingAgents = styled.div.attrs({
    children: 'no matching agents'
})`
    opacity: 25%;
    text-align: center;
`;

const PresentButton = styled.button`
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
`;

interface AgentFinderContextInterface {
    city: string;
    state: string;
    deal: any;
}

export const AgentFinderContext = React.createContext<AgentFinderContextInterface>({
    city: '',
    state: '',
    deal: {} as any
});

export interface AgentFinderParams {
    consumerId: string;
    dealId: string;
    location: string;
}

const AgentFinder:React.FC = () => {
    const [updateInquiry] = useMutation(updateInquiryMutationDocument);
    const {consumerId, dealId, location: _location} = useParams();
    const [location, setLocation] = useState(_location);
    const {data, refetch: refetchConsumer} = useQuery(consumerByIdQueryDocument, {variables:{id: consumerId}});
    const loginAs = useLoginAs();
    const [providers, setProviders] = useState<{[id:string]: PotentialProvider}>({});
    const [hoverProvider, setHoverProvider] = useState<PotentialProvider | null>();
    const [selectedProvider, setSelectedProvider] = useState<PotentialProvider | null>();
    const [markers, setMarkers] = useState<Array<Marker>>([]);
    const [transactionMarkers, setTransactionMarkers] = useState<Array<Marker>>([]);
    const [present, setPresent] = useState<string[]>([]);
    const redirect = useRedirect();

    const consumer: Consumer = data?.consumerById as Consumer;
    const inquiry: Inquiry | undefined = consumer?.inquiries?.nodes?.length ? consumer.inquiries.nodes[0] : undefined;
    const details = inquiry?.details;
    const engagebay = consumer?.engagebay;

    const deals = useMemo(() => {
        if (!engagebay?.deals)
            return [];

        return Object.entries(engagebay.deals).map(([uniqueId, deal]) => Object.assign({uniqueId}, deal));
    }, [engagebay?.deals]);

    const {deal, dealUniqueId, dealDetails} = useMemo(() => {
        if (!(deals && deals.length))
            return {};

        const entry = Object.entries(deals).find(([/*dealUniqueId*/, deal]) => String((deal as any).id) === dealId);

        if (!entry)
            return {};

        const deal: any = entry[1];
        const dealUniqueId = deal.uniqueId;

        const dealDetails = (details.deals ?? [])[dealUniqueId] ?? {};

        return {dealUniqueId, deal, dealDetails};
    }, [dealId, deals, details]);

    const { loading: retrievingDealPotentialProviders, data: dealPotentialProviders } = useQuery(dealPotentialProvidersQueryDocument, {
        variables: {
            dealUniqueId: String(deal?.uniqueId)
        }
    });

    useEffect(() => {
        if (retrievingDealPotentialProviders)
            setProviders({});
    }, [retrievingDealPotentialProviders]);

    const locationMatcher = (location ? location : deal?.name.replace(/^.*-\s+([^,]+,\s+\w\w) -.*$/, '$1'))?.match(/^([^,]+),\s+(\w\w)$/);
    const [, city, state] = locationMatcher ? locationMatcher : [];
    const zip = location && location.match(/^(\d{5})$/) ? location : null;

    const {data: cityGeoJsonResult} = useQuery(cityGeoJSONQueryDocument, {
        variables: {
            city,
            state
        }
    });
    const cityStateGeoJSON = cityGeoJsonResult?.cityGeoJson.features && cityGeoJsonResult?.cityGeoJson;

    const {data: zipGeoJsonResult} = useQuery(zipGeoJSONQueryDocument, {
        variables: {
            zip
        }
    });
    const zipGeoJSON = zipGeoJsonResult?.zipGeoJson.features && zipGeoJsonResult?.zipGeoJson;

    const locationGeoJSON = cityStateGeoJSON || zipGeoJSON;

    const transactions = selectedProvider?.provider?.transactions.nodes || hoverProvider?.provider?.transactions.nodes;

    const [notes, setNotes] = useState(details?.notes || '');

    useEffect(() => {
        setNotes(details?.notes ?? '');
    }, [details?.notes, setNotes]);

    const appLocation = (details && details.location && [details.location].map(({label}) => {
        const [city, state] = label.split(', ');
        return `${city}, ${state}`;
    })[0]) || null;

    useEffect(() => {
        if (!(inquiry && details))
            return;

        const addresses = inquiry?.inquiryAddresses.nodes;

        const markers: Marker[] = [];

        if (addresses.length) {
            const favorites = details?.favorites;
            const selling = details?.selling?.address;

            addresses.forEach(address => {
                let type: 'house' | 'sign' | 'city' | undefined;

                if (favorites && favorites.includes(address.address))
                    type = 'house';
                else if (selling && selling === address.address)
                    type = 'sign';

                if (address?.ebDealUniqueId && address.ebDealUniqueId !== String(deal.uniqueId))
                    return;

                if (!type) {
                    // TODO: remove address
                } else {
                    const price = address?.price ? `$${Number(address.price).toLocaleString()}` : undefined;

                    const marker: Marker = {
                        position: {
                            lat: parseFloat(address.latitude), lng: parseFloat(address.longitude)
                        },
                        type,
                        title: `${price} | ${address.address}`
                    };

                    markers.push(marker);
                }
            });
        }

        setMarkers(markers);

        if (dealPotentialProviders?.dealPotentialProviders) {
            setProviders(dealPotentialProviders.dealPotentialProviders.nodes.reduce<{[id:string]: PotentialProvider}>((map, potentialProvider) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const provider = useFragment(PotentialProviderFragment, potentialProvider.provider);

                if (provider?.id)
                    map[provider.id] = potentialProvider as PotentialProvider;

                return map;
            }, {}));
        }
    }, [inquiry, details, setMarkers, setProviders, deal?.uniqueId, dealPotentialProviders]);

    const [cityStateLoading, setCityStateLoading] = useState(false);

    useEffect(() => {
        if (consumerId || !(city && state))
            return;

        setCityStateLoading(true);

        apolloClient.query({
            query: cityStatePotentialProvidersQueryDocument,
            variables: {
                city,
                state
            }
        }).then(result => {
            const potentialProviders = result.data.cityStatePotentialProviders?.nodes;

            if (potentialProviders) {
                if (0 < potentialProviders.length) {
                    setProviders(potentialProviders.reduce<{[id:string]: PotentialProvider}>((map, potentialProvider) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        const provider = useFragment(PotentialProviderFragment, potentialProvider.provider);

                        if (provider?.id)
                            map[provider.id] = potentialProvider as PotentialProvider;

                        return map;
                    }, {}));
                }
            }
        }).finally(() => setCityStateLoading(false));
    }, [consumerId, city, state, setProviders, setCityStateLoading])

    useEffect(() => {
        if (consumerId || !zip)
            return;

        setCityStateLoading(true);

        apolloClient.query({
            query: zipPotentialProvidersQueryDocument,
            variables: {
                zip
            }
        }).then(result => {
            console.log({result})
            const potentialProviders = result.data.zipPotentialProviders?.nodes;

            if (potentialProviders) {
                if (0 < potentialProviders.length) {
                    setProviders(potentialProviders.reduce<{[id:string]: PotentialProvider}>((map, potentialProvider) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        const provider = useFragment(PotentialProviderFragment, potentialProvider.provider);

                        if (provider?.id)
                            map[provider.id] = potentialProvider as PotentialProvider;

                        return map;
                    }, {}));
                }
            }
        }).finally(() => setCityStateLoading(false));
    }, [consumerId, zip, setProviders, setCityStateLoading])

    const buying = (details && details.buying) || null;
    // const selling = (details && details.selling) || null;
    const invitations = consumer?.account?.invitations?.nodes || [];
    const email = findContact(consumer?.account?.contacts, 'email', 'address');

    const priceForSort = useMemo(() => {
        const addressNodes = (inquiry?.inquiryAddresses.nodes || [])
            .filter(node => {
                const price = node?.price;
                return price && 0 < parseInt(price);
            });

        const addressPriceData = addressNodes
            .reduce((data, node) => {
                return {
                    total: data.total + parseInt(node.price),
                    count: data.count+1
                }
            }, { total: 0, count: 0});

        const average = addressPriceData.count === 0 ? undefined : addressPriceData.total / addressPriceData.count;

        if (average)
            return average;

        return deal?.amount || 0;
    }, [inquiry, deal?.amount]);

    useEffect(() => {
        const transactions = selectedProvider?.provider?.transactions.nodes;

        if (!(transactions && 0 < transactions.length)) {
            setTransactionMarkers([]);
            return;
        }

        const transactionMarkers: Marker[] = transactions.filter(t => t && t.location).map(t => {
            let color = 'grey';

            if (priceForSort) {
                const percent = 100 * Math.max(.5, Math.min(1.5, t.closePrice / priceForSort));

                // color = `hsl(${Math.floor((percent - 50) * 2.25)}, 100%, 50%)`;

                if (percent < 75)
                    color = 'red';
                else if (125 < percent)
                    color = 'blue';
                else
                    color = 'green';
            }

            const date = new Date(t.closeDate).toDateString()
                .replace(/^\S+\s+/, '')
                .replace(/(\s\d{4})$/, ',$1');

            return ({
                position: {lat: t.location.latitude, lng: t.location.longitude},
                label: '',
                type: 'transaction',
                color,
                title: `$${t.closePrice.toLocaleString()} | ${date}`,
                transaction: t
            });
        });

        setTransactionMarkers(transactionMarkers);
    }, [selectedProvider, setTransactionMarkers, priceForSort])

    // const lead = (inquiry?.leads?.nodes || []).find(lead => lead.ebDealUniqueId === dealUniqueId);
    const connectedTo = deal?.agent;
    // console.log({connectedTo});

    const inquiryDeal = useMemo(() => {
        const inquiryDeals = inquiry?.providers?.deals;

        return ((inquiryDeals && inquiryDeals[deal.uniqueId]) || {}) as {[id: string]: any};
    }, [inquiry?.providers?.deals, deal?.uniqueId]);

    useEffect(() => {
        if (!(providers && inquiryDeal)) {
            setPresent([]);
            return;
        }

        const present: string[] = [];

        Object.entries(inquiryDeal).forEach(([agentId, agentSettings]) => {
            if (!agentSettings?.present)
                return;

            const provider = providers[agentId];

            if (provider?.provider?.email)
                present.push(provider.provider.email);
        });

        if (0 < present.length)
            setPresent(present);
    }, [inquiryDeal, providers, setPresent]);

    const {matchingProviders, potentialProviders} = useMemo(() => {
        const {
            matchingProviders,
            potentialProviders
        } = Object.values(providers).reduce<ProviderFilterResult>((map, potentialProvider) => {
            const provider = potentialProvider.provider;

            if (provider && provider.docusignStatus !== 'Declined' && provider.status?.status !== 'Rejected') {
                if (potentialProvider.provider?.signed)
                    map.matchingProviders.push(potentialProvider);
                else
                    map.potentialProviders.push(potentialProvider);
            }

            return map;
        }, {matchingProviders: [], potentialProviders: []});

        // const addresses = (inquiry?.details?.favorites || [])
        //     .concat(inquiry?.details?.selling?.address)
        //     .map((address: string) => {
        //         const match = address?.match(/,\s*([^,]+),\s*([A-Z]{2})(?:\s+(\d{5}))?/);
        //
        //         if (match) {
        //             const [, city, state, zip] = match;
        //
        //             return {city, state, zip};
        //         }
        //
        //         return null;
        //     })
        //     .filter((address: any) => !!address);

//        if (addresses.length === 0 && inquiry.)

        // function stringEquals(a: string|undefined|null, b: string|undefined|null) {
        //     return a && b && (a||'').trim().toLowerCase() === (b||'').trim().toLowerCase();
        // }

        const providerScore = Object.values(providers).reduce((map, provider) => {
            if (provider.provider?.id) {
                // const {city, state} = (provider.provider?.info?.location) || {city:'', state:''};
                //
                // const locationScore = addresses.reduce((score: number, address: any) => {
                //         const stateMatch = stringEquals(address.state, state);
                //         const cityMatch = stateMatch && stringEquals(address.city, city);
                //
                //         return score + (stateMatch ? 0 : -1) + (cityMatch ? 1 : 0);
                //     }, 0);
                //
                // const averagePrice = getPriceInfo(provider.provider.transactions.nodes).average || 0;
                // const priceScore = (priceForSort - Math.abs(averagePrice - priceForSort)) / priceForSort;

                const distanceScore = Math.max(0, (100 - (provider.distance || 0)) / 100);

                const nearestTransaction = provider.provider.transactions.nodes
                    .filter(transaction => {
                        const yearsAgo = (transaction.closeDate && (Date.now() - new Date(transaction.closeDate).getTime()) / (365.25*24*60*60*1000)) || 5;

                        return yearsAgo <= 3;
                    })
                    .reduce((nearest, transaction: any) => {
                    // const yearsAgo = (transaction.closeDate && (Date.now() - new Date(transaction.closeDate).getTime()) / (365.25*24*60*60*1000)) || 5;

                    // const dateMultiplier = Math.max(0, (12 - yearsAgo) / 10);
                    //
                    // if (addresses.some((address: any) => stringEquals(address.zip, transaction.zip)))
                    //     data.score += 1.5 * dateMultiplier;
                    // else if (addresses.some((address: any) => stringEquals(address.city, transaction.city) && stringEquals(address.state, transaction.state)))
                    //     data.score += dateMultiplier;

                    if (/*yearsAgo < 3 &&*/ transaction.location.latitude && transaction.location.longitude) {
                        const txnLoc = {
                            lat: transaction.location.latitude,
                            lng: transaction.location.longitude
                        }

                        return markers.reduce((nearest, marker) => {
                            return Math.min(nearest, distance(txnLoc, marker.position)/* * (1 + 0.5 * Math.max(0, yearsAgo - 2))*/);
                        }, nearest);
                    }

                    return nearest;
                }, Number.MAX_SAFE_INTEGER);

                const transactionsScore = 2 * Math.max(0, (20 - nearestTransaction) / 20, 0);
                    // transactionData.score * 25 / (provider.provider.transactions.nodes.length || 1) +
                //     transactionData.nearest
                //         .slice(0, 1)
                //         .reduce((score, distance) => score + Math.max(0, (20 - distance) / 20), 0)
                // ;

                // const sortedTransactions = provider.provider.transactions.nodes.slice();
                // sortedTransactions.sort((a:any, b:any) => new Date(b.closeDate).getTime() - new Date(a.closeDate).getTime());

                // const txnAgeScore = sortedTransactions.slice(0, 4).reduce((score: number, transaction: any) => {
                //     const yearsAgo = (transaction.closeDate && (Date.now() - new Date(transaction.closeDate).getTime()) / (365.25*24*60*60*1000));
                //
                //     // if (yearsAgo < 2)
                //         return score + .125 * Math.max(0, 2 - yearsAgo) / 2;
                //
                //     // return score;
                // }, 0);

                const bizDevScore = provider.provider.bizDev ? 1 : 0;

                const score = distanceScore + transactionsScore + bizDevScore; // locationScore + priceScore + txnAgeScore;

                map[provider.provider.id] = {distanceScore, transactionsScore, bizDevScore, score};
            }

            return map;
        }, {} as {[id: string]: { distanceScore: number, transactionsScore: number,  bizDevScore: number, score: number }});

        const providerSort = (a:PotentialProvider, b:PotentialProvider) => {
            const aId = a?.provider?.id;
            const bId = b?.provider?.id;

            if (a?.provider?.engagebay?.id === connectedTo?.id)
                return -1;
            else if (b?.provider?.engagebay?.id === connectedTo?.id)
                return 1;

            const ap = inquiryDeal[aId] || (inquiry?.providers || {})[aId];
            const bp = inquiryDeal[bId] || (inquiry?.providers || {})[bId];

            const ar = ap?.rating || 0;
            const br = bp?.rating || 0;

            if (ar !== br)
                return br - ar;

            const ai = present.indexOf(a?.provider?.email || '');
            const bi = present.indexOf(b?.provider?.email || '');

            const apr = ai === -1 ? ai : present.length - ai;
            const bpr = bi === -1 ? bi : present.length - bi;

            if (apr !== bpr)
                return bpr - apr;

            const as = providerScore[aId]?.score || 0;
            const bs = providerScore[bId]?.score || 0;

            if (as !== bs)
                return bs - as;

            return (a?.distance || 0) - (b?.distance || 0);
        };

        matchingProviders.sort(providerSort);

        const debugging = false;

        const logProviderScore = (provider: PotentialProvider) => {
            if (provider?.provider?.name) {
                const scores = Object.entries(providerScore[provider.provider.id]).map(([key, value]) => `${key.replace(/Score.*/, '')}: ${(value||0).toFixed(2)}`);

                if (debugging) console.log(`${provider.provider.name} | ${scores.join(' | ')}`);
            }
        };

        if (debugging) console.log('==== Active ====');
        matchingProviders.forEach(logProviderScore);

        potentialProviders.sort(providerSort);

        if (debugging) console.log('==== Pending ====');
        potentialProviders.forEach(logProviderScore);

        return {matchingProviders, potentialProviders};
    }, [providers, inquiry, markers, connectedTo?.id, present, inquiryDeal]);

    const updateNotes: ChangeEventHandler<HTMLTextAreaElement> = e => {
        setNotes(e.currentTarget.value);
    }

    const saveNotes: ChangeEventHandler<HTMLTextAreaElement> = () => {
        updateInquiry({
            variables: {
                input: {
                    nodeId: inquiry?.nodeId || '',
                    patch: {
                        details: {
                            ...details,
                            notes
                        }
                    }
                }
            }
        }).then();
    };

    const [showSmsPopup, setShowSmsPopup] = useState(false);

    const [presentPopup, setPresentPopup] = useState(false);

    const onPresent = () => setPresentPopup(true);

    const [showConsultation, setShowConsultation] = useState(false);

    if (consumerId && !consumer)
        return <Spinner/>;

    const connectBy = deal?.connectToAgentByDate ? new Date(deal.connectToAgentByDate).toLocaleDateString() : '';

    const onSelect = (location:LocationOption|null) => {
        if (!location)
            return;

        setLocation(location.label);

        setProviders({});
    }

    const clearPresented = () => {
        const body = JSON.stringify({
            consumerId,
            dealUniqueId,
            Agents_Presented: ' '
        });

        fetch(`${api.endpoint}/eb-deal`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body
        })
            .then(() => refetchConsumer())
    }

    const connectTo = connectedTo?.id ? undefined : () => {
        window.open(`https://joinrbn.engagebay.com/home#deal/${deal.id}`, 'eb-connect');
    }

    const selectDeal = (e:any) => {
        const dealId = e.target.value;
        redirect(`/agent-finder/${consumerId}/${dealId}`);
    }

    const presentAgents = (present
        .map(email => matchingProviders.find(p => p.provider?.email.toLowerCase() === email?.toLowerCase()))
        .filter(p => !!p)) as PotentialProvider[];

    const presented = deal?.agentsPresented?.trim();

    const enableInviteAgent = (e:any) => {
        updateInquiry({
            variables: {
                input: {
                    nodeId: inquiry?.nodeId || '',
                    patch: {
                        details: {
                            ...details,
                            deals: {
                                ...details.deals,
                                [dealUniqueId]: {
                                    ...dealDetails,
                                    inviteEnabled: e.target.checked
                                }
                            }
                        }
                    }
                }
            }
        }).then();
    }

    return (
        <AuthenticatedPage title={deal?.name} fullWidth={true} padding="0 0 1rem">
            <AgentFinderContext.Provider value={{city, state, deal}}>
                {!presented && presentPopup && consumer && <PresentAgents consumer={consumer} deal={deal} agents={presentAgents} close={() => setPresentPopup(false)}/>}
                {consumer && <ConsumerInfo>
                    {showSmsPopup && inquiry && <SmsPopup consumer={consumer} inquiry={inquiry} close={() => setShowSmsPopup(false)}/>}
                    <div>App Info:</div>
                    <div>{appLocation}</div>
                    <div><a href={`/consumers/${consumer.id}`} onClick={loginAs(consumer.account)}>{consumer?.account?.firstName} {consumer?.account?.lastName}</a></div>
                    <div>{email} <FaSms onClick={() => setShowSmsPopup(true)}/></div>
                    <div>{buying?.price || 'selling'}</div>
                    <div>{buying?.timeframe}</div>
                    <div>{deal ? <a href={`https://joinrbn.engagebay.com/home#deal/${deal.id}`} target="engagebay">Deal Info</a> : `Deal Info`}:</div>
                    <div style={{marginRight: '1rem'}}>
                        <Select
                            style={{height: 'auto'}}
                            items={deals?.map((deal: any) => ({value: deal.id, label: `${deal.side || '(no side)'} - ${deal.milestone}`}))}
                            onChange={selectDeal}
                            value={dealId}
                        />
                    </div>
                    <div>
                        <Link to='/agent-db' state={{city, state}} target={window.localStorage.getItem('noTabs') ? undefined : '_blank'}>{city}, {state}</Link>
                        <label style={{marginLeft: '2rem'}}>
                            <input type="checkbox" onClick={enableInviteAgent} checked={dealDetails?.inviteEnabled}/>Enable Invite Agent
                        </label>
                    </div>
                    <div/>
                    <div>{deal?.amount ? formatPrice(deal.amount) : ''}</div>
                    <div>{connectBy}</div>
                    <InfoBottom>
                        {inquiry && <Addresses key={dealId} inquiry={inquiry} transactions={transactions} deal={deal}/>}
                        {invitations && 0 < invitations.length && (
                            <div>
                                <label>Invitations:</label>
                                <div>
                                    {invitations.map((invitation:any) => (
                                        <div key={invitation.email}>
                                            {invitation.firstName} {invitation.lastName} {invitation.email} {invitation.phone}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </InfoBottom>
                    <StyledTextArea placeholder="Notes..." value={notes} onChange={updateNotes} onBlur={saveNotes} id={`agent-finder-notes-${dealUniqueId}`}/>
                    {/*<ShowConsultationButton onClick={() => setShowConsultation(true)}>Consultation Form</ShowConsultationButton>*/}
                    {showConsultation && <Consultation consumer={consumer} close={() => setShowConsultation(false)}/>}
                </ConsumerInfo>}
                {!consumer && (
                    <LocationHeader>
                        <label>Location:</label> <AutoCompleteLocation onSelect={onSelect} defaultValue={{id: '', label: city && state ? `${city}, ${state}` : ''}}/>
                    </LocationHeader>
                )}
                <Results>
                    <ScrollBox>
                        <h2>Active{presented ? (
                            <PresentButton onClick={clearPresented}>Clear Presented</PresentButton>
                        ) : present.length ? (
                            <PresentButton onClick={onPresent}>Present {present.length}</PresentButton>
                        ) : ''}</h2>
                        {cityStateLoading && <Spinner/>}
                        {matchingProviders?.length ? (
                            <ProviderList>
                                {matchingProviders.map(potentialProvider => (
                                    <Provider
                                        key={potentialProvider.provider?.id}
                                        potentialProvider={potentialProvider}
                                        markers={markers}
                                        selectProvider={() => setSelectedProvider(provider => provider === potentialProvider ? null : potentialProvider)}
                                        selected={selectedProvider === potentialProvider}
                                        hovering={!selectedProvider && hoverProvider === potentialProvider}
                                        inquiry={inquiry}
                                        onMouseEnter={() => setHoverProvider(potentialProvider)}
                                        onMouseLeave={() => setHoverProvider(provider => provider === potentialProvider ? null : provider)}
                                        present={present.includes(potentialProvider.provider?.email)}
                                        togglePresent={() => {
                                            setPresent((array) =>  {
                                                if (!potentialProvider.provider?.email)
                                                    return array;

                                                if (array.includes(potentialProvider.provider?.email))
                                                    return array.filter(p => p !== potentialProvider.provider?.email);
                                                else {
                                                    const newArray = array.slice();
                                                    newArray.push(potentialProvider.provider?.email);
                                                    return newArray;
                                                }
                                            })
                                        }}
                                        agentsPresented={presented}
                                        agentsPresentedDate={deal?.agentsPresentedDate}
                                        connected={connectedTo?.id && potentialProvider.provider?.engagebay?.id === connectedTo?.id}
                                        connectTo={connectTo}
                                        deal={deal}
                                    />
                                ))}
                            </ProviderList>
                        ) : retrievingDealPotentialProviders ? <Spinner/> : <NoMatchingAgents/>}
                    </ScrollBox>
                    <Map markers={markers} transactionMarkers={transactionMarkers} providers={selectedProvider ? [selectedProvider] : hoverProvider ? [hoverProvider] : []} cityGeoJSON={locationGeoJSON} city={city} state={state}/>
                    <ScrollBox>
                        <h2>Pending</h2>
                        {cityStateLoading && <Spinner/>}
                        {potentialProviders?.length ? (
                            <ProviderList>
                                {potentialProviders.map(potentialProvider =>(
                                    <Provider
                                        key={potentialProvider.provider?.id}
                                        potentialProvider={potentialProvider}
                                        markers={markers}
                                        selectProvider={() => setSelectedProvider(provider => provider === potentialProvider ? null : potentialProvider)}
                                        selected={selectedProvider === potentialProvider}
                                        hovering={!selectedProvider && hoverProvider === potentialProvider}
                                        inquiry={inquiry}
                                        showStatus={true}
                                        onMouseEnter={() => setHoverProvider(potentialProvider)}
                                        onMouseLeave={() => setHoverProvider(provider => provider === potentialProvider ? null : potentialProvider)}
                                        deal={deal}
                                    />
                                ))}
                            </ProviderList>
                        ) : retrievingDealPotentialProviders ? <Spinner/> : <NoMatchingAgents/>}
                    </ScrollBox>
                </Results>
            </AgentFinderContext.Provider>
        </AuthenticatedPage>
    )
};


export default AgentFinder;

// const ShowConsultationButton = styled.button`
//   position: absolute;
//   left: 1em;
//   top: 50%;
//   transform: translateY(-50%);
// `;