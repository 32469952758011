import {graphql} from "./types";

export const userActivitySubscriptionDocument = graphql(`
    subscription UserActivityListener($topic: String!) {
        listen(topic: $topic) {
            relatedNode {
                nodeId
                ... on UserActivity {
                    account {
                        nodeId
			id
                        firstName
                        lastName
                    }
                    instanceId
                    path
                    data
                    createdAt
                }
            }
        }
    }
`);

export const userActivityMutationDocument = graphql(`
    mutation CreateUserActivity($input: CreateUserActivityInput!) {
        createUserActivity(input: $input) {
            userActivity {
                nodeId
                account {
                    nodeId
		    id
                    firstName
                    lastName
                }
                instanceId
                path
                data
                createdAt                
            }
        }
    }
`);

export const userActivityQueryDocument = graphql(`
    query UserActivitiesQuery($path: String!) {
        userActivities(condition: { path: $path }) {
            nodes {
                nodeId
                account {
                    nodeId
		    id
                    firstName
                    lastName
                }
                instanceId
                path
                data
                createdAt                
            }
        }
    }
`);
