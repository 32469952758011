import Overlay from "../../components/Overlay";
import styled from "styled-components";
import {Button} from "../../components/form";
import React from "react";
import {SwitchflyLedgerDetail, SwitchflyRefundDetail} from "../Consumers/PointsLedger";

interface Props {
    entry: any;
    onClose: () => void;
}

export default function Detail({entry, onClose}: Props) {
    const [showData, setShowData] = React.useState(false);
    
    return (
        <Overlay onClick={onClose}>
            <Popup>
                <Grid>
                    <label>Consumer:</label><span><a href={`/consumers/points/${entry.account.consumer?.id}`}>{entry.account.firstName} {entry.account.lastName}</a></span>
                    <label>Date:</label><span>{new Date(entry.createdAt).toLocaleString()}</span>
                    <label>Type:</label><span>{entry.entryType}</span>
                    <label>Points:</label><span>{entry.quantity.toLocaleString()}</span>
                    <label>Admin:</label><span>{entry.accountByCreatedBy.firstName} {entry.accountByCreatedBy.lastName}</span>
                    <label>Note:</label><span>{entry.note}</span>
                </Grid>
                {entry.data?.bookingId && entry.data?.metadata && <SwitchflyLedgerDetail data={entry.data}/>}
                {entry.data?.bookingId && entry.data?.cancelledProduct && <SwitchflyRefundDetail data={entry.data}/>}
                {Object.keys(entry.data).length > 0 && (
                    <>
                        <DataHeader onClick={() => setShowData(showData => !showData)}>{showData ? '' : 'Show '}Data</DataHeader>
                        {showData && (
                            <pre>
                                {JSON.stringify(entry.data, null, 2)}
                            </pre>
                        )}
                    </>
                )}
                <Button onClick={onClose}>Close</Button>
            </Popup>
        </Overlay>
    )
}

const Popup = styled.div`
    background: #fff;
    padding: .5rem 1rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;

    pre {
        margin: 0;
        max-width: 800px;
        max-height: 200px;
        overflow: auto;
    }
    
    h4 {
        margin-bottom: 0;
    }
    
    ${Button} {
        margin-top: 1rem;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: .5rem;
    margin-bottom: 1rem;
    
    label {
        text-align: right;
    }
    
    span {
        max-width: 400px;
    }
`;

const DataHeader = styled.h4`
    cursor: pointer;
`;