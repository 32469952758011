import React from 'react';
import {useSubscription} from "@apollo/client";
import {
    updateAccountSubscriptionDocument,
    updateConsumerSubscriptionDocument,
    updateContactSubscriptionDocument,
    updateInquirySubscriptionDocument,
    updateInquiryAddressSubscriptionDocument,
    updateInvitationSubscriptionDocument,
    updateLeadSubscriptionDocument,
    updateProviderSubscriptionDocument
} from "./gql/UpdateListener";
import useViewer from "./lib/use-viewer";

function UpdateListener() {
    useSubscription(updateAccountSubscriptionDocument);
    useSubscription(updateConsumerSubscriptionDocument);
    useSubscription(updateContactSubscriptionDocument);
    useSubscription(updateInquirySubscriptionDocument);
    useSubscription(updateInquiryAddressSubscriptionDocument);
    useSubscription(updateInvitationSubscriptionDocument);
    useSubscription(updateLeadSubscriptionDocument);
    useSubscription(updateProviderSubscriptionDocument);

    return null;
}

export default function UpdateListenerWrapper() {
    const {viewer} = useViewer();

    if (!viewer)
        return null;

    return <UpdateListener/>;
}