import {graphql} from "./types";

export default graphql(`
    query Providers($options: JSON) {
      allProviders(options: $options) {
        nodes {
            ...ProviderData
        }
      }
    }
`);