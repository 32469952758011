import React from 'react';
import {Route} from "react-router";

import ChangePassword from "./ChangePassword";
import Dashboard from "./Dashboard";
import {Routes} from "react-router-dom";

const _ = () => (
    <Routes>
        <Route index element={<Dashboard/>}/>
        <Route path="change-password" element={<ChangePassword/>}/>
    </Routes>
);

export default _;