import React from 'react'
import {createRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from "styled-components";
import {ApolloProvider} from "@apollo/client";
import * as serviceWorker from './serviceWorker';

import {apolloClient} from './config';

import AppRoutes from './AppRoutes'
import theme from './styles';

import UpdateListener from "./UpdateListener";
import UserActivity from "./UserActivity";

function Wrapper() {
    return (
        <ApolloProvider client={apolloClient}>
            <UpdateListener/>
            <HelmetProvider>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <AppRoutes/>
                        <UserActivity/>
                    </BrowserRouter>
                </ThemeProvider>
            </HelmetProvider>
        </ApolloProvider>
    );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Wrapper/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
