import {useCallback} from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useRedirect = (navigationWarning?:string, validNavigation?:string|RegExp|(() => boolean)) => {
    const navigate = useNavigate();

    const {pathname:previousPath} = useLocation();

    return useCallback((path: string, state?: any) => {
        window.setTimeout(() => navigate(path, state), 0);

        return previousPath;
    }, [navigate, previousPath]);
};

export default useRedirect;

