import React, {useEffect, useMemo, useState} from 'react';
import AuthenticatedPage from '../../components/AuthenticatedPage';
import {useParams} from "react-router";
import {useQuery} from "@apollo/client";
import consumerByIdQueryDocument from "../../gql/Consumer";
import {Button, Buttons, Input, Select} from "../../components/form";
import styled from "styled-components";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import {api, JWT_KEY} from "../../config";
import useLoginAs from "../../lib/use-login-as";
import PointsLedger from "./PointsLedger";

// const Form = styled.form`
//     .invalid {
//         background: #fcc;
//     }
//
//     label.override {
//         padding-left: 2rem;
//     }
//
//     label.override + label {
//         margin-top: 2em;
//     }
// `;

const Agent = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
`;

const Photo = styled.div<{url:string}>`
    background-image: url(${({url}) => url});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 75px;
    height: 100px;
`;

const AgentInfo = styled.div`
    flex-grow: 1;
`;

const Points:React.FC = () => {
    const {consumerId} = useParams();
    const loginAs = useLoginAs();
    const {data, loading, refetch} = useQuery(consumerByIdQueryDocument, {variables:{id:consumerId}});

    const consumer = data?.consumerById;

    const [dealAmount, setDealAmount] = useState('');
    const [txnCommissionSplit, setTxnCommissionSplit] = useState('');
    const [agreementPercent, setAgreementPercent] = useState('50');
    const [bonusOffer, setBonusOffer] = useState('');
    const [closeOfEscrowDate, setCloseOfEscrowDate] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentReceivedDate, setPaymentReceivedDate] = useState('');
    const [justinReceivedAmount, setJustinReceivedAmount] = useState('');
    const [miaReceivedAmount, setMiaReceivedAmount] = useState('');
    const [propertyAddress, setPropertyAddress] = useState('');
    const [closingCompanyName, setClosingCompanyName] = useState('');
    const [closingCompanyContactName, setClosingCompanyContactName] = useState('');
    const [closingCompanyEmail, setClosingCompanyEmail] = useState('');
    const [closingCompanyPhone, setClosingCompanyPhone] = useState('');
    const [paymentIssuer, setPaymentIssuer] = useState('');

    const [overridePaymentAmount, setOverridePaymentAmount] = useState(false);
    const [overrideJustinClearedAmount, setOverrideJustinClearedAmount] = useState(false);
    const [overrideMiaClearedAmount, setOverrideMiaClearedAmount] = useState(false);

    const [milestone, setMilestone] = useState('');

    const [deal, setDeal] = useState<any>();

    const engagebay = data?.consumerById?.engagebay || '';
    const allDeals = useMemo(() => {
        if (!engagebay?.deals)
            return [];

        return Object.entries(engagebay.deals).map(([uniqueId, deal]) => Object.assign({uniqueId}, deal));
    }, [engagebay?.deals]);
    const transactionDeals = useMemo(() => allDeals.filter((deal:any) => {
        if (!deal)
            return false;

        return deal.track === 'Transactions' &&
            ['In Escrow', 'Property Details Rcvd', 'Property Details Received', 'Payment Instructions Sent', 'Payment Instructions Confirmed', 'Closed', 'Payment Sent', 'Payment Received', 'Waiting for AmEx Connection', 'Test Points Issued', 'Test Points Confirmed', 'Points Issued']
            .includes(deal.milestone)
    }), [allDeals]);
    const deals = 0 < transactionDeals.length ? transactionDeals : allDeals;

    const [pushingToEB, setPushingToEB] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    function getPayload() {
        if (!deal)
            return false;

        const payload: any = {  };

        function different(a: any, b: any) {
            let av = a||'';

            if (typeof av === 'string' && av.match(/^-?\d+(?:\.\d+)?$/))
                av = Number(av);
            let bv = b||'';
            if (typeof bv === 'string' && bv.match(/^-?\d+(?:\.\d+)?$/))
                bv = Number(bv);

            return av !== bv;
        }

        const amount = Number(dealAmount.replace(/[^.\d]+/g, ''));
        if (different(amount, deal.amount))
            payload.amount = amount;
        if (different(txnCommissionSplit, deal.txnCommissionSplit))
            payload.txnCommissionSplit = txnCommissionSplit;
        if (different(agreementPercent, deal.agreementPercent))
            payload.agreementPercent = agreementPercent;
        if (different(bonusOffer, deal.bonusOffer)) {
            if (!bonusOffer) {
                setBonusOffer(' ');
                payload.bonusOffer = ' ';
            }
            else
                payload.bonusOffer = bonusOffer;
        }
        const coed = String(closeOfEscrowDate ? Math.round(new Date(`${closeOfEscrowDate} 00:00 MST`).getTime() / 1000) : '');
        if (different(coed, deal.closeOfEscrowDate))
            payload.closeOfEscrowDate = coed;
        const pa = paymentAmount.replace(/[^.\d]+/g, '');
        if (different(pa, deal.paymentAmount))
            payload.paymentAmount = pa;
        const prd = String(paymentReceivedDate ? Math.round(new Date(`${paymentReceivedDate} 00:00 MST`).getTime() / 1000) : '');
        if (different(prd, deal.paymentReceivedDate))
            payload.paymentReceivedDate = prd;
        const jra = justinReceivedAmount.replace(/[^.\d]+/g, '');
        if (different(jra, deal.justinReceivedAmount))
            payload.justinReceivedAmount = jra;
        const mra = miaReceivedAmount.replace(/[^.\d]+/g, '');
        if (different(mra, deal.miaReceivedAmount))
            payload.miaReceivedAmount = mra;

        if (different(propertyAddress, deal.propertyAddress))
            payload.propertyAddress = propertyAddress;
        if (different(closingCompanyName, deal.closingCompanyName))
            payload.closingCompanyName = closingCompanyName;
        if (different(closingCompanyContactName, deal.closingCompanyContactName))
            payload.closingCompanyContactName = closingCompanyContactName;
        if (different(closingCompanyEmail, deal.closingCompanyEmail))
            payload.closingCompanyEmail = closingCompanyEmail;
        if (different(closingCompanyPhone, deal.closingCompanyPhone))
            payload.closingCompanyPhone = closingCompanyPhone;
        if (different(paymentIssuer, deal.paymentIssuer))
            payload.paymentIssuer = paymentIssuer;

        if (different(milestone, deal.milestone))
            payload.milestone = milestone;

        return 0 < Object.keys(payload).length && payload;
    }

    const payload = getPayload();

    const dirty = !!payload;

    // if (dirty)
    //     console.log({payload});

    const pushToEB = () => {
        const payload = getPayload();

        if (!payload)
            return;

        setPushingToEB(true);

        const data = {
            consumerId,
            dealId: deal.id,
            dealUniqueId: deal.uniqueId,
            ...payload
        };

        fetch(`${api.endpoint}/admin-points`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                if (json.error)
                    window.alert(json.error);
            })
            .finally(() => {
                window.setTimeout(() => {
                    refetch().then(() => setPushingToEB(false));
                }, 1000);
            });
    }

    // const [sendingPointsEstimateEmail, setSendingPointsEstimateEmail] =  useState(false);

    // const sendPointsEstimateEmail = () => {
    //     setSendingPointsEstimateEmail(true);
    //
    //     const data = {
    //         consumerId,
    //         dealId: deal.id,
    //         dealUniqueId: deal.uniqueId,
    //         sendPointsEstimateEmail: true
    //     };
    //
    //     fetch(`${api.endpoint}/admin-points`, {
    //         method: 'POST',
    //         headers: {
    //             Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(data)
    //     })
    //         .then(response => response.json())
    //         .then(json => {
    //             if (json.error) {
    //                 setSendingPointsEstimateEmail(false);
    //                 window.alert(json.error);
    //             }
    //         })
    //         .finally(() => {
    //             window.setTimeout(() => {
    //                 refetch().then(() => setSendingPointsEstimateEmail(false));
    //             }, 1000);
    //         });
    // };

    // const [issuingTestPoints, setIssuingTestPoints] = useState(false);

    // const issueTestPoints = () => {
    //     setIssuingTestPoints(true);
    //
    //     const data = {
    //         consumerId,
    //         dealId: deal.id,
    //         dealUniqueId: deal.uniqueId,
    //         issueTestPoints: true
    //     };
    //
    //     fetch(`${api.endpoint}/admin-points`, {
    //         method: 'POST',
    //         headers: {
    //             Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(data)
    //     })
    //         .then(response => response.json())
    //         .then(json => {
    //             if (json.error) {
    //                 setIssuingTestPoints(false);
    //                 window.alert(json.error);
    //             }
    //         })
    //         .finally(() => {
    //             window.setTimeout(() => {
    //                 refetch().then(() => setIssuingTestPoints(false));
    //             }, 1000);
    //         });
    // }

    const [issuingPoints, setIssuingPoints] = useState(false);

    const issuePoints = () => {
        if (!(deal.paymentReceivedDate || window.confirm(`Payment has not been received. Are you sure?`)))
            return;

        setIssuingPoints(true);

        const data = {
            consumerId,
            dealId: deal.id,
            dealUniqueId: deal.uniqueId,
            issueRemainingPoints: true
        };

        fetch(`${api.endpoint}/admin-points`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    setIssuingPoints(false);
                    window.alert(json.error);
                }
            })
            .finally(() => {
                window.setTimeout(() => {
                    refetch().then(() => setIssuingPoints(false));
                }, 1000);
            });
    }

    useEffect(() => {
        // set initial deal
        if (deals.length === 0)
            return;

        setDeal((deal: any) => {
            if (deal) {
                const d = deals.find(d => d.uniqueId === deal.uniqueId);
                if (d)
                    return d;
            }

            return deals[0];
        });
    }, [deals, setDeal]);

    useEffect(() => {
        if (['Connected', 'In Escrow'].includes(deal?.milestone) && dealAmount && txnCommissionSplit) {
            // TODO: add 'in escrow' tag to deal
            setMilestone('Property Details Received');
        }
    }, [deal?.milestone, dealAmount, txnCommissionSplit, setMilestone])

    useEffect(() => {
        if (!deal)
            return;

        setDealAmount(deal.amount?.toLocaleString() || '');
        setAgreementPercent(deal.agreementPercent || '50');
        setTxnCommissionSplit(deal.txnCommissionSplit || '');

        if (deal.bonusOffer?.trim())
            setBonusOffer(deal.bonusOffer);
        else {
            // TODO: check for completed issue first
            // const lto = data?.consumerById?.account?.campaign?.lto?.trim();
            // const partner = data?.consumerById?.account?.campaign?.partner?.trim();
            //
            // if (lto && partner)
            //     setBonusOffer(lto.replace(/\s.*/, ` points from ${partner} Limited Time Offer`));
        }

        if (deal.closeOfEscrowDate && deal.closeOfEscrowDate.match(/^\d+$/)) {
            const date = new Date(parseInt(deal.closeOfEscrowDate) * 1000);
            setCloseOfEscrowDate(date.toISOString().substring(0, 10));
        }
        else
            setCloseOfEscrowDate('');

        setPaymentAmount(deal.paymentAmount ? Number((deal.paymentAmount || '').replace(/[^.\d]+/g, '')).toLocaleString() : '');

        if (deal.paymentReceivedDate && deal.paymentReceivedDate.match(/^\d+$/)) {
            const date = new Date(parseInt(deal.paymentReceivedDate) * 1000);
            setPaymentReceivedDate(date.toISOString().substring(0, 10));
        }
        else
            setPaymentReceivedDate('');

        setJustinReceivedAmount(deal.justinReceivedAmount ? Number((deal.justinReceivedAmount || '').replace(/[^.\d]+/g, '')).toLocaleString() : '');
        setMiaReceivedAmount(deal.miaReceivedAmount ? Number((deal.miaReceivedAmount || '').replace(/[^.\d]+/g, '')).toLocaleString() : '');
        setPropertyAddress(deal.propertyAddress || '');
        setClosingCompanyName(deal.closingCompanyName || '');
        setClosingCompanyContactName(deal.closingCompanyContactName || '');
        setClosingCompanyEmail(deal.closingCompanyEmail || '');
        setClosingCompanyPhone(deal.closingCompanyPhone || '');
        setPaymentIssuer(deal.paymentIssuer || '');

        setMilestone(deal.milestone);

    }, [data?.consumerById?.account?.campaign?.lto, data?.consumerById?.account?.campaign?.partner, deal]);

    const changeHandler = (fn:any) => (e:any) => {
        fn(e.target.value);
    }

    const dealPrice = dealAmount ? parseInt(dealAmount.replace(/[^.\d]+/g, '')) : 0;

    const expectedPaymentAmount = (dealPrice * (
                Number(txnCommissionSplit.replace(/[^.\d]+/g, '') || '0') / 100) *
            (Number(agreementPercent.replace(/[^.\d]+/g, '') || '0') / 100)
        );

    const pr = Number(paymentAmount?.replace(/,/g, '') || '0');
    const paymentMatched = Math.abs(expectedPaymentAmount - pr) < 1;
    const justinMatched = Math.abs(pr - Number(justinReceivedAmount?.replace(/,/g, '') || '0')) < 1;
    const miaMatched = Math.abs(pr - Number(miaReceivedAmount?.replace(/,/g, '') || '0')) < 1;


    const bonusPoints = parseInt(
        (bonusOffer?.trim() || '0')
            .replace(/\s*([,\d]+).*/, '$1')
            .replace(/\D/, '')
    );

    const points = Math.min(dealPrice, Math.ceil(expectedPaymentAmount * 2/3 * 100));

    const selectDeal = (e:any) => {
        const id = e.target.value;
        const deal = deals.find((deal:any) => String(deal.id) === id);

        if (deal) {
            setDeal(deal);
        }
    }
// console.log({points, dirty, issuingPoints, pointsIssueDate: deal?.pointsIssueDate, milestone: deal?.milestone, payload});
    const paymentIncorrect = paymentAmount && !paymentMatched;
    const justinIncorrect = paymentAmount && justinReceivedAmount && !justinMatched;
    const miaIncorrect = paymentAmount && miaReceivedAmount && !miaMatched;

    // const enableSendEstimate = points && !dirty && !deal?.pointsEstimateEmailSent && !sendingPointsEstimateEmail && !deal?.milestone.match(/Points Issued/);
    const enableIssueRbnPoints = points && !dirty && !issuingPoints && !deal?.pointsIssueDate && !deal?.milestone.match(/Points Issued|Test Points Confirmed/);


    const pointsIssuedDate = deal?.pointsIssuedDate ? ` ${new Date(deal.pointsIssuedDate * 1000).toISOString().substring(0, 10)}` : '';

    const milestones = ['', 'In Escrow', 'Property Details Rcvd', 'Payment Instructions Sent', 'Closed', 'Payment Received', 'Waiting for AmEx Connection', 'Test Points Issued', 'Test Points Confirmed', 'Points Issued'];

    if (deal?.milestone && !milestones.includes(deal.milestone))
        milestones.unshift(deal.milestone);

    const inquiry = (consumer?.inquiries?.nodes?.length && consumer.inquiries.nodes[0]) || undefined;
    const lead = (inquiry?.leads?.nodes || []).find((lead:any) => lead.ebDealUniqueId === String(deal?.uniqueId));
    let connectedTo = lead?.provider;

    if ((deal?.agent && !connectedTo) || (!deal?.agent && connectedTo) || (deal?.agent?.email !== connectedTo?.email)) {
        const dealAgent = deal?.agent;
        console.error({dealAgent, connectedTo});
        if (!connectedTo)
            connectedTo = dealAgent;
    }

    const engagebayId = connectedTo?.engagebay?.id;

    const uniqueId = deal?.uniqueId;

    return (
        <AuthenticatedPage title={`Points - ${consumer?.account?.firstName} ${consumer?.account?.lastName}`}>
            {loading ? <Spinner/> : (
                <>
                    <h2 style={{marginBottom:0}}>{consumer?.account?.firstName} {consumer?.account?.lastName} &nbsp;&nbsp;&nbsp;&nbsp;{deal?.id && <a href={`https://joinrbn.engagebay.com/home#deal/${deal.id}`} target="_blank" rel="noreferrer">EB</a>}</h2>
                    <h4 style={{marginTop:0}}>Available RBN Rewards Points: {(consumer?.account?.pointsAvailable ?? 0).toLocaleString()}</h4>
                    <Select
                        label="EB Deal ID"
                        items={deals?.map((deal: any) => ({value: deal.id, label: `${deal.id} - ${deal.side} - ${deal.milestone}`}))}
                        onChange={selectDeal}
                        style={{backgroundColor: transactionDeals.length === 0 ? '#f99' : 1 < (deals?.length || 0) ? '#ffd' : '#fff'}}
                    />
                    <Tabs>
                        {['Property Details', 'Agent/Closing', 'Payment', 'Points Ledger'].map((title, i) => (
                            <Tab key={title} className={activeTab === i ? 'active' : 'inactive'} onClick={() => setActiveTab(i)}>
                                {title}
                            </Tab>
                        ))}
                    </Tabs>
                    {activeTab === 0 && (
                        <>
                            <Input label="Deal Amount" value={dealAmount} onChange={changeHandler(setDealAmount)} id={`deal-amount-${uniqueId}`}/>
                            <Input type="date" label="Close of Escrow Date" value={closeOfEscrowDate} onChange={changeHandler(setCloseOfEscrowDate)} id={`coe-date-${uniqueId}`}/>
                            <Input label="Property Address" value={propertyAddress} onChange={changeHandler(setPropertyAddress)} id={`property-address${uniqueId}`}/>
                        </>
                    )}
                    {activeTab === 1 && (
                        <>
                            {connectedTo ? (
                                <Agent>
                                    <Photo url={connectedTo.image?.url ? `https://api.joinrbn.com${connectedTo.image.url}` : ''}/>
                                    <AgentInfo>
                                        <div><a href={`/providers/${connectedTo?.id}`} onClick={loginAs(connectedTo?.account)}>{connectedTo.name}</a></div>
                                        <div><a href={`mailto:${connectedTo.email}`}>{connectedTo.email}</a></div>
                                        <div><a href={`tel:+1${connectedTo.phone?.replace(/\D+/g, '').replace(/^1/, '')}`}>{connectedTo.phone}</a></div>
                                        <div>{engagebayId && <a href={`https://joinrbn.engagebay.com/home#list/0/subscriber/${engagebayId}`} target="engagebay">EB Link</a>}</div>
                                    </AgentInfo>
                                </Agent>
                            ) : (
                                <Error>No Agent</Error>
                            )}
                            <Input label="Txn Commission Split" value={txnCommissionSplit} onChange={changeHandler(setTxnCommissionSplit)} id={`commission-${uniqueId}`}/>
                            <Input label="Agreement Percent" value={agreementPercent} onChange={changeHandler(setAgreementPercent)} id={`agreement-pct-${uniqueId}`}/>
                            <Input label="Closing Company Name" value={closingCompanyName} onChange={changeHandler(setClosingCompanyName)} id={`closing-company-${uniqueId}`}/>
                            <Input label="Closing Company Contact Name" value={closingCompanyContactName} onChange={changeHandler(setClosingCompanyContactName)} id={`closing-contact-${uniqueId}`}/>
                            <Input label="Closing Company Email" value={closingCompanyEmail} onChange={changeHandler(setClosingCompanyEmail)} id={`closing-email-${uniqueId}`}/>
                            <Input label="Closing Company Phone" value={closingCompanyPhone} onChange={changeHandler(setClosingCompanyPhone)} id={`closing-phone-${uniqueId}`}/>
                            <Select label="Which entity will issue payment?" items={["Title", "Attorney", "Brokerage"]} value={paymentIssuer} onChange={changeHandler(setPaymentIssuer)} id={`closing-issuer-${uniqueId}`}/>
                        </>
                    )}
                    {activeTab === 2 && (
                        <>
                            <Input label="Bonus Offer" value={bonusOffer} onChange={changeHandler(setBonusOffer)} id={`bonus-${uniqueId}`}/>
                            <Input label={`Expected Payment Amount`} value={expectedPaymentAmount.toLocaleString()} disabled id={`expected-${uniqueId}`}/>
                            <Input label={`Actual Payment Amount`} value={paymentAmount} onChange={changeHandler(setPaymentAmount)} className={paymentIncorrect ? 'invalid' : 'valid'} id={`actual-${uniqueId}`}/>
                            {paymentIncorrect && <label className="override"><input type="checkbox" checked={overridePaymentAmount} onChange={() => setOverridePaymentAmount(!overridePaymentAmount)}/> Override Payment Amount Match</label>}
                            <Input type="date" label="Payment Received Date" value={paymentReceivedDate} onChange={changeHandler(setPaymentReceivedDate)}  id={`received-${uniqueId}`}/>
                            <Input label={`Cleared Amount (Justin)`} value={justinReceivedAmount} onChange={changeHandler(setJustinReceivedAmount)} className={justinIncorrect ? 'invalid' : 'valid'} id={`justin-${uniqueId}`}/>
                            {justinIncorrect && <label className="override"><input type="checkbox" checked={overrideJustinClearedAmount} onChange={() => setOverrideJustinClearedAmount(!overrideJustinClearedAmount)}/> Override Justin Cleared Amount Match</label>}
                            <Input label={`Cleared Amount (Mia)`} value={miaReceivedAmount} onChange={changeHandler(setMiaReceivedAmount)} className={miaIncorrect ? 'invalid' : 'valid'} id={`mia-${uniqueId}`}/>
                            {miaIncorrect && <label className="override"><input type="checkbox" checked={overrideMiaClearedAmount} onChange={() => setOverrideMiaClearedAmount(!overrideMiaClearedAmount)}/> Override Mia Cleared Amount Match</label>}
                        </>
                    )}
                    {activeTab === 3 ? (
                        <PointsLedger consumer={consumer}/>
                    ) : (<>
                        <Buttons><Button onClick={pushToEB} disabled={!dirty || pushingToEB}>Sav{pushingToEB ? 'ing...' : 'e'}</Button></Buttons>
                        {milestone && milestone !== deal.milestone && <MilestoneChanging>Milestone will change to {milestone}</MilestoneChanging>}
                        <h2>{points.toLocaleString()} + {bonusPoints.toLocaleString()} = {(points + bonusPoints).toLocaleString()} Points</h2>
                        {dealPrice < 500000 && 0 < bonusPoints && (
                            <Error>Issuing Bonus Points with Deal Amount &lt; $500,000</Error>
                        )}
                        {dealAmount && (
                            <Buttons>
                                {/*<Button disabled={!enableSendEstimate} onClick={sendPointsEstimateEmail}>{deal?.pointsEstimateEmailSent ? `Estimate Sent ${deal.pointsEstimateEmailSent}` : 'Send Points Estimate Email'}</Button>*/}
                                <Button disabled={!enableIssueRbnPoints} onClick={issuePoints}>{issuingPoints ? 'Issuing' : deal?.pointsIssuedDate ? 'Issued' : 'Issue'} {(points + bonusPoints).toLocaleString('EN-us', { maximumFractionDigits: 0 })} RBN Rewards Points{pointsIssuedDate}{deal.paymentReceivedDate ? '' : ' (payment not received)'}</Button>
                            </Buttons>
                        )}
                        <div style={{marginTop: "2rem"}}><a href={`https://joinrbn.com/email/consumer/points-estimate?id=${consumerId}&dealUniqueId=${deal?.uniqueId}`} target="email-preview">View Points Estimate Email</a></div>
                    </>)}
                </>
            )}
        </AuthenticatedPage>
    )
}

export default Points;

const Tabs = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: 32px;
`;

const Tab = styled.li`
    padding: 4px;
    margin-right: 32px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    
    &.active {
        border-bottom-color: #0d3f88;
    }
`;

const MilestoneChanging = styled.div`
    text-align: right;
    color: #0d3f88;
`;