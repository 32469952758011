import Providers from './Providers';
import {Route, Routes} from "react-router-dom";
import React from "react";
import MapPage from "./Map";

const _Providers = () => {
    return (
        <Routes>
            <Route index element={<Providers/>}/>
            <Route path="map" element={<MapPage/>}/>
            <Route path=":id" element={<Providers/>}/>
        </Routes>
    );
};

export default _Providers;