import {graphql} from "./types";

export default graphql(`
query ProvidersList($options: JSON) {
  allProviders(options: $options) {
    nodes {
      nodeId
      id
      name
      engagebayId
      account {
        id
        firstName
        lastName
        campaign
      }
      email
      brokerageName
      status
      syntheticStatus
      signed
      docusignStatus
      docusignSigned
      profileComplete
      location
      createdAt
      public
      approved
      info
    }
  }
}
`);