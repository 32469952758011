import React, {ChangeEventHandler, useEffect, useState} from 'react';
import {useMutation} from "@apollo/client";
import {updateInquiryMutationDocument} from "../../gql/Inquiry";
import {updateProviderMutationDocument} from "../../gql/Provider";
import {Inquiry, PotentialProvider} from "../../gql/types/graphql";


interface AgentBlurbProps {
    potentialProvider: PotentialProvider;
    inquiry: Inquiry | undefined;
}

export const AgentBlurb: React.FC<AgentBlurbProps> = ({potentialProvider, inquiry}) => {
    const provider = potentialProvider.provider;
    const info = (inquiry?.providers && inquiry.providers[provider?.id]) || {};
    const [updateInquiry] = useMutation(updateInquiryMutationDocument);
    const [updateProvider] = useMutation(updateProviderMutationDocument);

    const [blurbIndex, setBlurbIndex] = useState<number>(info.blurbIndex || 0);

    const [blurb, setBlurb] = useState(blurbIndex < (provider?.info.blurbs?.length || 0) ? provider?.info.blurbs[blurbIndex] : '');

    const selectBlurb: ChangeEventHandler<HTMLSelectElement> = e => {
        const blurbIndex = parseInt(e.target.value);

        setBlurbIndex(blurbIndex);

        const providers = Object.assign({}, inquiry?.providers);

        if (provider)
            providers[provider.id] = Object.assign({}, providers[provider.id], {blurbIndex});

        updateInquiry({
            variables: {
                input: {
                    nodeId: inquiry?.nodeId || '',
                    patch: {
                        providers
                    }
                }
            }
        }).then();
    }

    useEffect(() => {
        const blurbs: string[] = provider?.info.blurbs || [];

        setBlurb(blurbIndex < blurbs.length ? blurbs[blurbIndex] : '');
    }, [blurbIndex, provider?.info.blurbs, setBlurb]);

    const blurbChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
        setBlurb(e.target.value);
    }

    const saveBlurb = () => {
        if (!(provider && blurb))
            return;

        const blurbs = (provider.info.blurbs || []).slice();

        blurbs[blurbIndex] = blurb;

        updateProvider({
            variables: {
                input: {
                    nodeId: provider.nodeId,
                    patch: {
                        info: Object.assign({}, provider.info, {blurbs})
                    }
                }
            }
        }).then();
    }

    return (
        <>
            <label>Why we think {provider?.name} is a good match:</label>
            <div style={{display: 'grid', gridTemplateColumns: '1fr auto'}}>
                <textarea onChange={blurbChange} value={blurb} onBlur={saveBlurb} id={`blurb-${provider?.id}`}/>
                <select onChange={selectBlurb} value={blurbIndex}>
                    {((provider?.info.blurbs || []) as string[]).map((blurb: string, i) => (
                        <option key={i} value={i}>{1+i}</option>
                    ))}
                    <option value={(provider?.info.blurbs || []).length}>New</option>
                </select>
            </div>
        </>

    );
}