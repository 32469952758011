import React from 'react';

import {StyledInput} from "./Input";
import styled from "styled-components";
import Label from "./Label";
import Required from "./Required";

interface ComponentProps {
    label?: string,
    grow?: boolean,
    value?: string,
    required?: boolean,
    [key:string]: any
}

const TextArea = styled(StyledInput).attrs({
    as: 'textarea'
})`
  padding: 1em 10px;
  font-size: inherit;
  min-height: 8em;
`;

const _ = ({label, grow=false, value, ...props}: ComponentProps) => (
    <Label grow={grow}>
        {label && (
            <span>{label}{props.required && <Required/>}</span>
        )}
        <TextArea {...props} value={value}/>
    </Label>
);

export default _;