import React, {useState} from 'react';
import useLoginAs from "../../lib/use-login-as";
import {FaEnvelope, FaFileAlt, FaHandshake, FaSms, FaStar, FaTimesCircle, FaCheck} from "react-icons/fa";
import {TransactionInfo} from "./TransactionInfo";
import {ProviderDeal} from "./ProviderDeal";
import {Marker, SearchLink} from "./AgentFinder";
import styled from "styled-components";
import {Photo} from "./Photo";
import {useMutation} from "@apollo/client";
import {updateInquiryMutationDocument} from "../../gql/Inquiry";
import {Inquiry, PotentialProvider} from "../../gql/types/graphql";
import {AgentPresentedSelected} from "./AgentPresentedSelected";

const ProviderWrapper = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 75px 4px 1fr;
    grid-column-gap: .35rem;
    grid-row-gap: .5rem;
    padding: 1rem;
    max-width: 350px;
    
    &:nth-child(odd) {
        background-color: #eee;
    }
    
    ${Photo} {
    }
    
    &.hovering {
        background: rgba(0, 255, 0, 50%);
    }
    
    &.selected {
        background: rgba(0, 0, 255, 50%);
    }
    
    &.connected {
        background: gold;
    }
    
    a + a {
        margin-left: 1rem;
    }
`;

const ProgressBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
`;

const ProviderRating = styled.div`
    max-width: 320px;
    grid-column-start: 1;
    grid-column-end: -1;
    display: flex;
    justify-content: end;
    align-items: center;
    
    span {
        display: block;
        width: 1rem;
    }
    
    svg {
        cursor: pointer;
    }
    
    svg:hover {
        color: #666;
    }
`;

const ProviderInfo = styled.div`
    width: 100%;
    max-width: 240px;
    //overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ProfileLink = styled.a`
    display: inline-block;
    width: 228px;
    max-width: 228px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const FlexBox = styled.div`
    display: flex;
`;
const StyledSpan = styled.span`
    white-space: normal;
`;
export interface ProviderProps {
    potentialProvider: PotentialProvider;
    markers: Array<Marker>;
    selectProvider: () => void;
    selected: boolean;
    hovering: boolean;
    inquiry: Inquiry|undefined;
    showStatus?: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
    present?: boolean;
    togglePresent?: () => void;
    agentsPresented?: string;
    agentsPresentedDate?: string;
    connected?: boolean;
    connectTo?: (data: PotentialProvider) => void;
    deal?: any;
}

export interface UpdateInquiryDealProps {
    rating?: number;
    notes?: string;
    sms?: string;
    present?: boolean;
}

export const Provider:React.FC<ProviderProps> = (props) => {
    const {
        potentialProvider,
        selectProvider,
        selected,
        hovering,
        inquiry,
        showStatus,
        onMouseEnter,
        onMouseLeave,
        present,
        agentsPresented,
        agentsPresentedDate,
        connected,
        connectTo,
        deal
    } = props;

    const {togglePresent: _togglePresent, ...remainingProps} = props;

    const provider = potentialProvider.provider;
    const {city, state} = (provider?.info?.location) || {city:'', state:''};
    const loginAs = useLoginAs();
    const deals = inquiry?.providers?.deals || {};
    const inquiryDeal = deal && deals[deal.uniqueId];
    const info = (inquiryDeal && inquiryDeal[provider?.id]) || (inquiry && inquiry.providers && inquiry.providers[provider?.id]) || {};

    // const priceInfo = getPriceInfo(potentialProvider?.provider?.transactions.nodes);

    const onClick = (e: any) => {
        if (e.target.tagName !== 'DIV')
            return;

        selectProvider();
    }

    const profileComplete = provider?.profileComplete || 0;
    const progressColor = profileComplete > 66 ? '#0c0' : profileComplete > 33 ? '#cc0' : '#900';

    const rating = info.rating || 0;
    const notes = info.notes || '';

    let distance:any = potentialProvider.distance || 0;

    if (distance < 0.001) {
        distance = '';
    }
    else {
        if (typeof distance === 'number') {
            if (distance < 1)
                distance = distance.toFixed(2);
            else if (distance < 10)
                distance = distance.toFixed(1);
            else
                distance = distance.toFixed(0);
        }

        distance = <span style={{fontSize: '75%'}}>({distance} mile{distance !== '1' ? 's' : ''})</span>;
    }

    const agentPresented = !!(agentsPresented?.match(provider?.name || 'N/A') || agentsPresented?.match(provider?.email || 'N/A'));

    const engagebayId = potentialProvider?.provider?.engagebay?.id;

    const [showProviderDeal, setShowProviderDeal] = useState<boolean>(false);

    const bizDev = provider?.bizDev;

    const [updateInquiry] = useMutation(updateInquiryMutationDocument);

    const updateInquiryDeal = (update:UpdateInquiryDealProps) => {
        if (!provider)
            return;

        if (update.rating === rating)
            update.rating = 0;

        const providers = Object.assign({ deals: {} }, inquiry?.providers);

        providers.deals = {...providers.deals};

        const inquiryDeal = Object.assign({}, providers.deals[deal.uniqueId]);

        inquiryDeal[provider.id] = Object.assign({}, inquiryDeal[provider.id], update);

        providers.deals[deal.uniqueId] = inquiryDeal;;

        updateInquiry({
            variables: {
                input: {
                    nodeId: inquiry?.nodeId || '',
                    patch: {
                        providers
                    }
                }
            }
        }).then();
    };

    const updateRating = (rating:number) => () => updateInquiryDeal({rating});

    const togglePresent = () => {
        if (!_togglePresent)
            return;

        updateInquiryDeal({present: !present});

        _togglePresent();
    }

    return (
        <ProviderWrapper
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            className={ selected ? 'selected' : hovering ? 'hovering' : connected ? 'connected' : ''}
        >
            {inquiry && <ProviderRating>
                {present && <PresentCheck><FaCheck/></PresentCheck>}

                {agentPresented && (
                    <>
                        {connected || connectTo ? <FaHandshake size={24} color={connected ? '#33f' : '#ccc'} title={connected ? 'Connected' : `Connect`} onClick={connectTo ? () => connectTo(potentialProvider) : undefined}/> : ''}
                        <span/>
                        <FaEnvelope color="#33f" title={`Presented ${agentsPresentedDate}`}/>
                    </>
                )}
                {info.sms && (
                    <>
                        <span/>
                        <FaSms color="#33f" title={info.sms}/>
                    </>
                )}
                <span/>
                {[1,2,3].map((value) => (
                    <FaStar key={value} color={value <= rating ? '#090' : '#ccc'} onClick={updateRating(value)}/>
                ))}
                <span/>
                <FaFileAlt color={notes ? '#33f' : '#ccc'} title={notes} onClick={() => setShowProviderDeal(true)}/>
                <span/>
                <FaTimesCircle color={rating === -1 ? '#f00' : '#ccc'} onClick={updateRating(-1)}/>
                <span/>
            </ProviderRating>}
            <Photo url={provider?.image?.url || ''}/>
            <ProgressBar><div style={{background: progressColor, height: `${profileComplete || 0}%`}} title={`${profileComplete || 0}%`}/></ProgressBar>
            <ProviderInfo>
                <div><ProfileLink href={`/providers/${provider?.id}`} onClick={loginAs(provider?.account)}>{provider?.name}</ProfileLink></div>
                {showStatus && <div>{provider?.syntheticStatus}</div>}
                <div>
                    {engagebayId && <a href={`https://joinrbn.engagebay.com/home#list/0/subscriber/${engagebayId}`} target="engagebay">EB Link</a>}
                    <SearchLink term={`${provider?.name} real estate ${city} ${state}`}>Google</SearchLink>
                    <ProfileLink href={`/agent-finder/agent-profile/${provider?.id}`} style={{display: 'inline-flex'}}>Edit Profile</ProfileLink>
                </div>
                <div>{city && `${city}, ${state}`} {distance}</div>
                {bizDev ? <BizDev/> : <TransactionInfo providerId={potentialProvider?.provider?.id}/>}
                <FlexBox><AgentPresentedSelected agent={provider}/>&nbsp;<StyledSpan style={{}} title={provider?.syntheticStatus || ''}>{provider?.syntheticStatus}</StyledSpan></FlexBox>
            </ProviderInfo>
            {showProviderDeal && (
                <ProviderDeal
                    info={info}
                    updateInquiryDeal={updateInquiryDeal}
                    close={() => setShowProviderDeal(false)}
                    togglePresent={togglePresent}
                    {...remainingProps}
                />
            )}
        </ProviderWrapper>
    );
}


const PresentCheck = styled.div`
    position: absolute;
    top: 1em;
    left: 1em;
    color: ${({theme: {accent}}) => accent};
`;

const BizDev = styled.div.attrs({
    children: <span>Biz Dev</span>
})`
  span {
    background: ${({theme: {accent}}) => accent};
    color: #fff;
    padding: 0 8px;
    border-radius: 5px;
  }
`;