import {useMemo} from "react";
import {FaDownload} from "react-icons/fa";

const Papa = require('papaparse');

interface Props {
    data: any;
}

export default function CSV({ data }: Props) {
    const csv = useMemo(() => {
        const list = data.slice().sort((a:any, b:any) => b.createdAt.localeCompare(a.createdAt)).map((row:any) => {
            const date = new Date(row.createdAt);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return{
                Date: date.toLocaleDateString(),
                Time: `${hours}:${minutes}:${seconds}`,
                'First Name': row.account.firstName,
                'Last Name': row.account.lastName,
                Email: row.account.contacts.nodes.find((c: any) => c.type === 'email').value.address,
                Phone: row.account.contacts.nodes.find((c: any) => c.type === 'phone').value.number,
                Type: row.entryType,
                Points: row.quantity,
                Admin: row.accountByCreatedBy.firstName + ' ' + row.accountByCreatedBy.lastName,
                Note: row.note,
            }
        });

        // rows.unshift([
        //     'date',
        //     'First Name',
        //     'Last Name',
        //     'Email',
        //     'Phone',
        //     'Type',
        //     'Points',
        //     'Admin',
        //     'Note'
        // ]);

        // const csv = encodeURIComponent(rows.map((fields: any[]) => fields.map((field:any) => {
        //     if (field instanceof Array) {
        //         const unique = field.filter((value, index, self) => self.indexOf(value) === index);
        //
        //         field = unique.join(', ');
        //     }
        //
        //     if (typeof field === 'string')
        //         return field ? `"${field.replaceAll('"', '""')}"` : '';
        //     else
        //         return field;
        // }).join(',')).join('\n'));

        const csv = encodeURIComponent(Papa.unparse(list, {
            quotes: (value:any) => typeof value === 'string' && /[,\r\n]/.test(value)
        }));

        return `data:text/plain;charset=utf-8,${csv}`;
    }, [data])

    return (
        <a style={{marginLeft: '4rem'}} href={csv} download='rewards-log.csv'><FaDownload/></a>
    );
}