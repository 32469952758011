import React, {useState} from 'react';
import styled from "styled-components";

import AuthenticatedPage from "../../../components/AuthenticatedPage";
import {Button, Buttons, Form, Input} from "../../../components/form";
import {Full} from "../../../components/form/Box";
import Error from "../../../components/Error";
import {useSetPassword} from "../../../lib/authentication";


const Wrapper = styled.div`
  width: 500px;
  position: relative;
  margin: 0 auto;
  
  ${Buttons} + a {
    display: block;
    color: #fff;
    margin-top: 1rem;
    text-decoration: none;
    font-size: 90%;
    text-align: right;
  }
`;

const Requirements = styled.div`
  color: ${({theme:{lightGreen}}) => lightGreen};
  margin: 1rem 0 2rem;
`;

const ChangePassword = () => {
    const {setPassword, fields, updateField, error} = useSetPassword();
    const [updated, setUpdated] = useState(false);

    const {password, confirmPassword} = fields;

    const valid = password === confirmPassword &&
        6 <= password.length &&
        password.match(/\d/) &&
        password.match(/[^\w]/);

    const submitHandler = (e:any) => {
        e.preventDefault();

        if (!valid)
            return;

        setPassword(e)
            .then(() => setUpdated(true))
            .catch(() => {});
    };

    return (
        <AuthenticatedPage title="Set Password">
            <Wrapper>
                <Form onSubmit={submitHandler} box={true}>
                    <Full heading={updated ? 'Password Updated' : 'Set Password'}>
                        {!updated ?
                            <>
                                <Requirements>
                                    Password must be at least 6 characters and include at least one number and one symbol.
                                </Requirements>
                                <Input name="password" value={password} onChange={updateField} label="Password"
                                       type="password" required={true} autoComplete="new-password"
                                       pattern=".{6,}"/>
                                <Input name="confirmPassword" value={confirmPassword} onChange={updateField}
                                       label="Confirm Password" type="password" required={true}
                                       autoComplete="new-password"/>
                                <Error error={error}/>
                                <Buttons>
                                    <Button variation="light-blue" disabled={!valid}>Save</Button>
                                </Buttons>
                            </> :
                            <Buttons>
                                <Button variation="light-blue" to="/dashboard">Continue</Button>
                            </Buttons>
                        }
                    </Full>
                </Form>
            </Wrapper>
        </AuthenticatedPage>
    );
};

export default ChangePassword;