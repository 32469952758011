import React, {ChangeEventHandler, FocusEventHandler, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";

import {SearchLink} from "./AgentFinder";
import {addInquiryAddressMutationDocument, updateInquiryAddressMutationDocument} from "../../gql/Inquiry";
import {useMutation} from "@apollo/client";
import {Inquiry, ProviderTransaction} from "../../gql/types/graphql";

const StyledInput = styled.input`
    border: 1px solid #666;
`;

const AddressesWrapper = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 1rem;
    
    label {
        text-align: right;
        grid-column: 1;
    }
`;

type Transactions = ProviderTransaction;

interface Props {
    inquiry: Inquiry;
    transactions: Transactions[] | null | undefined;
    deal: any;
}

const Addresses: React.FC<Props> = ({inquiry, transactions, deal}) => {
    const favorites: string[] | undefined = inquiry.details?.favorites;
    const selling = inquiry.details?.selling?.address;

    return (
        <AddressesWrapper>
            {selling && (
                <>
                    <label>Selling:</label>
                    <Address address={selling} key={selling} inquiry={inquiry} transactions={transactions} deal={deal}/>
                </>
            )}
            {favorites && (
                <>
                    <label>Favorites:</label>
                    {favorites.map(address => <Address address={address} key={address} inquiry={inquiry} transactions={transactions} deal={deal}/>)}
                </>
            )}
        </AddressesWrapper>
    )
};

export default Addresses;

const AddressContainer = styled.div`
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    
    input {
        width: 8em;
        text-align: right;
    }
    
    span {
        font-size: 80%;
    }
`;

function cleanPrice(input: any) {
    if (typeof input === 'number')
        return input;

    if (!input)
        return null;

    return parseInt(input.replace(/[^.\d]+/g, ''))
}

interface AddressProps {
    address: string;
    inquiry: Inquiry;
    transactions: any[] | null | undefined;
    deal: any;
}

const Address: React.FC<AddressProps> = ({address, inquiry, transactions, deal}) => {
    const [addInquiryAddress] = useMutation(addInquiryAddressMutationDocument);
    const [updateInquiryAddress] = useMutation(updateInquiryAddressMutationDocument);

    const inquiryAddress = useMemo(() => {
        return inquiry.inquiryAddresses.nodes.find((inquiryAddress:any) => inquiryAddress.address === address);
    }, [address, inquiry]);

    const [input, setInput] = useState(String(inquiryAddress?.price || ''));
    const [connected, setConnected] = useState(inquiryAddress?.ebDealUniqueId === String(deal?.uniqueId));

    useEffect(() => {
        if (!address)
            return;

        if (!inquiryAddress)
            lookupAddress(address, inquiry.id, addInquiryAddress);
    }, [address, inquiry.id, inquiryAddress, addInquiryAddress]);

    const priceHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (!inquiryAddress)
            return;

        const input = e.target.value;
        setInput(input);
    }

    const connectHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
        const connected = e.target.checked;

        const ebDealUniqueId = connected ? String(deal.uniqueId) : null;

        if (!inquiryAddress || (!inquiryAddress?.ebDealUniqueId && !ebDealUniqueId) || inquiryAddress.ebDealUniqueId === ebDealUniqueId)
            return;

        // console.log({ebDealUniqueId});

        updateInquiryAddress({
            variables: {
                input: {
                    nodeId: inquiryAddress.nodeId,
                    patch: {
                        ebDealUniqueId
                    }
                }
            }
        }).then();

        setConnected(connected);
    }

    const {city, state} = useMemo(() => {
        if (address) {
            const match = address.match(/,\s*([^,]+),\s*([A-Z]{2})(?:\s+(\d{5}))?/);

            if (match) {
                const [, city, state] = match;

                return {city, state};
            }
        }

        return {city: null, state: null}
    }, [address]);

    const matchingTransactionInfo = useMemo(() => {
        if (!(transactions && city && state))
            return '';

        const c = city.toLowerCase();
        const s = state.toLowerCase();

        const filtered = transactions.filter(({city, state}) => (city||'').toLowerCase() === c && (state||'').toLowerCase() === s);
        filtered.sort((a, b) => new Date(a.closeDate).getTime() - new Date(b.closeDate).getTime());

        if (filtered.length === 0)
            return '';

        const latest = new Date(filtered[0].closeDate);
        const average = filtered.reduce((total, {closePrice}) => total + closePrice, 0) / filtered.length;

        return `${filtered.length} txn${1 < filtered.length ? 's' : ''} - avg $${average.toLocaleString('en-US', {maximumFractionDigits: 0})} - most recent ${latest.getMonth() + 1}/${latest.getFullYear()}`;
    }, [transactions, city, state]);

    useEffect(() => {
        setInput(inquiryAddress?.price ?? '');
    }, [inquiryAddress?.price, setInput]);

    const priceBlurHandler: FocusEventHandler<HTMLInputElement> = () => {
        if (!inquiryAddress)
            return;

        const price = cleanPrice(input);

        if (price !== cleanPrice(inquiryAddress.price)) {
            updateInquiryAddress({
                variables: {
                    input: {
                        nodeId: inquiryAddress.nodeId,
                        patch: {
                            price
                        }
                    }
                }
            }).then();
        }
    }

    return (
        <AddressContainer>
            <SearchLink term={address} style={{opacity: inquiryAddress?.ebDealUniqueId && inquiryAddress.ebDealUniqueId !== String(deal.uniqueId) ? .25 : 1}}/>
            <div>
                <input type="checkbox" style={{width:'auto'}} title="connected to this deal" checked={connected} onChange={connectHandler} id={`agent-finder-address-checkbox-${inquiryAddress?.nodeId}`}/>
                <StyledInput placeholder="price" disabled={!inquiryAddress} value={input} onChange={priceHandler} onBlur={priceBlurHandler} id={`agent-finder-address-price-${inquiryAddress?.nodeId}`}/>
            </div>
            <span>{matchingTransactionInfo}</span>
        </AddressContainer>
    );
};

const lookupPromises: {[address: string]: Promise<void>} = {};

function lookupAddress(address: string, inquiryId: string, addInquiryAddress: any) {
    if (!lookupPromises[address]) {
        lookupPromises[address] = new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder();

            console.log(`asking google for location of ${address}`);

            // find lat/lon and create new inquiryAddress
            geocoder.geocode({address}, (results: any, status: any) => {
                if (status === 'OK') {
                    const location = results[0].geometry.location;

                    const latitude = location.lat();
                    const longitude = location.lng();

                    if (latitude && longitude)
                        addInquiryAddress({
                            variables: {
                                input: {
                                    inquiryId,
                                    address,
                                    latitude,
                                    longitude
                                }
                            }
                        }).then(resolve)
                    else
                        reject();
                } else {
                    console.error({status, results});
                    reject();
                }
            });
        });
    }
}