import {useState} from "react";

function useFields(fields:Array<string>, defaultValues:any={}, sessionStorageKey?:string) {
    defaultValues = defaultValues || {};

    fields.forEach(field => {
        if (defaultValues[field] === undefined)
            defaultValues[field] = '';
    });

    if (sessionStorageKey) {
        const json = window.sessionStorage.getItem(sessionStorageKey);

        if (json) {
            const data = JSON.parse(json);

            Object.assign(defaultValues, data);
        }
        else {
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(defaultValues));
        }
    }

    const [values, setValues] = useState(defaultValues);

    const updateField = (e:any, value?:any) => {
        const newValues = Object.assign({}, values);

        if (typeof e === 'string') {
            const key:string = e;

            Object.assign(newValues, {[key]: value});
        }
        else {
            if (!e.target)
                return console.error({e});

            if (!e.target.name) {
                console.error('input missing name attribute', e.target);
                return;
            }

            Object.assign(newValues, {[e.target.name]: e.target.value});
        }

        setValues(newValues);

        if (sessionStorageKey) {
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(newValues));
        }
    };

    const updateFields = (update:any) => {
        const newValues = Object.assign({}, values, update);

        setValues(newValues);

        if (sessionStorageKey) {
            window.sessionStorage.setItem(sessionStorageKey, JSON.stringify(newValues));
        }
    };

    const reset = () => setValues(defaultValues);

    return {fields: values, updateField, updateFields, reset};
}

export default useFields;