import {ApolloClient, InMemoryCache, split, HttpLink, from} from '@apollo/client';
import {getMainDefinition} from "@apollo/client/utilities";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";

import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

import {JWT_KEY} from './authentication';
import {clearJWT} from "../lib/authentication";
import {relayStylePagination} from "@apollo/client/utilities";
import {api} from "./api";
// import WriteOptions = Cache.WriteOptions;
// import ReadOptions = Cache.ReadOptions;

const httpLink = new HttpLink({
    uri: `${api.server}/graphql`,
//    fetch
});


const wsLink = new GraphQLWsLink(createClient({
    url: `${api.server.replace(/^https/, 'wss')}/graphql`,
    shouldRetry: () => true,
    connectionParams: () => ({
        Authorization: localStorage.getItem(JWT_KEY) ? `Bearer ${localStorage.getItem(JWT_KEY)}` : ''
    })
}));

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);

        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
)

const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);

        const e:any = networkError;

        if (e.statusCode === 401 || e.statusCode === 403) {
            // clear JWT and reload
            clearJWT();
            document.location.reload();
        }
    }

    console.log({graphQLErrors, networkError});

    if (graphQLErrors?.find((error:any) => error.errcode === '42501' && !error.message.match(/st_distance/))) {
        debugger;
        clearJWT();
        document.location = '/login';
    }
});

const authLink = setContext((_, { headers: originalHeaders }) => {
    const headers = Object.assign({}, originalHeaders);

    // get the authentication token from local storage if it exists
    const jwt = localStorage.getItem(JWT_KEY);
    // return the headers to the context so httpLink can read them

    if (jwt) {
        const [,b64encoded,] = jwt.split('.');

        const {exp}: any = JSON.parse(atob(b64encoded));
        const expiresAt = exp && (Number(exp) * 1000);

        if (expiresAt < Date.now()) {
            // JWT is expired
            clearJWT();
            document.location.reload();
        }
        else {
            // console.log(`jwt expires at ${new Date(expiresAt)}`);
            Object.assign(headers, {Authorization: `Bearer ${jwt}`});
        }
    }

    return {headers};
});

// class IndexedDbMemoryCache extends InMemoryCache {
//     constructor(config: any) {
//         super(config);
//     };
//
//     read<T>(options:ReadOptions<any, any>) {
//         console.log({read:{query: options}});
//
//         return super.read<T>(options);
//     }
//
//     write(options:WriteOptions<any, any>) {
//         console.log({write:{options}});
//
//         return super.write(options);
//     }
// }

const cache = new InMemoryCache({
    dataIdFromObject: (object:any) => object.nodeId || null,
    typePolicies: {
        Query: {
            fields: {
                allConsumers: relayStylePagination(),
                allProviders: relayStylePagination(),
            }
        }
    }
});

export const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, splitLink]),
    cache
});
