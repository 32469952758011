import React from "react";

import {StyledInput} from './Input';
import Label from './Label';
import Required from './Required';
import styled from "styled-components";

interface ComponentProps {
    label?: string,
    grow?: boolean,
    items?: {[value: string]: string;}|Array<string>|Array<{value: string, label: string}>,
    [key:string]: any
}

const Select = styled(StyledInput).attrs({
    as: 'select'
})`
`;

const _ = ({label, grow=false, items, children, ...props}: ComponentProps) => {
    const options = items instanceof Array ?
            items.map(item => {
                const value = typeof item === 'string' ? item : item.value;
                const label = typeof item === 'string' ? item : item.label;
                return <option key={value} value={value}>{label}</option>;
            }) :
            items && Object.entries(items).map(([key, value]) => <option key={key} value={key}>{value}</option>
    );

    return (
        <Label grow={grow}>
            {label && (
                <span>{label}{props.required && <Required/>}</span>
            )}
            <Select {...props}>
                {children}
                {options}
            </Select>
        </Label>
    );
};

export default _;