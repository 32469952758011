import {graphql} from "./types";

export const updateAccountSubscriptionDocument = graphql(`
    subscription UpdateAccountListener {
        listen(topic: "update:Account") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Account {
                    firstName
                    lastName
                    campaign
                }
            }
        }
    }
`);

export const updateConsumerSubscriptionDocument = graphql(`
    subscription UpdateConsumerListener {
        listen(topic: "update:Consumer") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Consumer {
                    vendors
                    engagebay
                    providersInArea
                    consultation
                    deletedAt
                }
            }
        }
    }
`);

export const updateContactSubscriptionDocument = graphql(`
    subscription UpdateContactListener {
        listen(topic: "update:Contact") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Contact {
                    type
                    value
                }
            }
        }
    }
`);

export const updateInquirySubscriptionDocument = graphql(`
    subscription UpdateInquiryListener {
        listen(topic: "update:Inquiry") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Inquiry {
                    details
                    detailsConfirmed
                    matches
                    providers
                    flags
                    ebDealUniqueId
                }
            }
        }
    }
`);

export const updateInquiryAddressSubscriptionDocument = graphql(`
    subscription UpdateInquiryAddressListener {
        listen(topic: "update:InquiryAddress") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on InquiryAddress {
                    address
                    latitude
                    longitude
                    price
                    ebDealUniqueId
                }
            }
        }
    }
`);

export const updateInvitationSubscriptionDocument = graphql(`
    subscription UpdateInvitationListener {
        listen(topic: "update:Invitation") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Invitation {
                    firstName
                    lastName
                    email
                    phone
                    info
                }
            }
        }
    }
`);

export const updateLeadSubscriptionDocument = graphql(`
    subscription UpdateLeadListener {
        listen(topic: "update:Lead") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Lead {
                    status
                    info
                    history
                    transaction
                    ebDealUniqueId
                }
            }
        }
    }
`);

export const updateProviderSubscriptionDocument = graphql(`
    subscription UpdateProviderListener {
        listen(topic: "update:Provider") {
            relatedNodeId
            relatedNode {
                nodeId
                ... on Provider {
                    type
                    name
                    gender
                    title
                    slug
                    phone
                    email
                    info
                    transactionHistory
                    approved
                    public
                    tier
                    setupComplete
                    profileComplete
                    approvalRequested
                    minPrice
                    maxPrice
                    status
                    syntheticStatus
                    bizDev
                    languages
                    specialties
                    nickname
                    pronouns
                    geoJson
                    areaCenter
                    engagebay
                    engagebayId
                    deletedAt
                }
            }
        }
    }
`);