import {graphql} from './types';

const RemoveAccountMutationDocument =  graphql(`
    mutation RemoveAccount($input: RemoveAccountInput!) {
        removeAccount(input: $input) {
            clientMutationId
            
            account {
		id
                consumer {
                    ...ConsumerData
                }
                
                provider {
                    ...ProviderData
                }
            }
        }
    }
`);

export default RemoveAccountMutationDocument;
