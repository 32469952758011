import gql from "graphql-tag";

export const UpdateTransactionLocationMutation = gql`
    mutation UpdateTransactionLocation($input: UpdateTransactionLocationInput!) {
        updateTransactionLocation(input: $input) {
            providerTransaction {
                nodeId
                lookupFailed
                location
            }
        }
    }
`;

export const UpdateTransactionLocationLookupFailedMutation = gql`
    mutation UpdateTransactionLocationLookupFailed($input: UpdateTransactionLocationLookupFailedInput!) {
        updateTransactionLocationLookupFailed(input: $input) {
            providerTransaction {
                nodeId
                lookupFailed
                location
            }
        }
    }
`;

export const GetNextProviderTransactionQuery = gql`
    query GetNextProviderTransaction($t: String!) {
        getNextProviderTransaction(t: $t) {
            nodeId
            id
            address1
            address2
            city
            state
            zip
            location
            lookupFailed
        }
    }
`;

export const UpdateProviderTransactionByIdMutation = gql`
    mutation UpdateProviderTransactionById($input: UpdateProviderTransactionByIdInput!) {
        updateProviderTransactionById(input: $input) {
            providerTransaction {
                nodeId
                id
                address1
                address2
                city
                state
                zip
                location
                lookupFailed
            }
        }
    }
`;