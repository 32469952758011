import nearestZipQueryDocument from '../gql/NearestZip';
import {apolloClient} from './apollo';

export function getLatitudeLongitude() {
    const apiScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]') as HTMLScriptElement | null;
    const googleKey = apiScript?.src.replace(/^.*key=([^&]+).*$/, '$1');

    return fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${googleKey}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            considerIp: true
        })
    })
        .then(response => response.json())
        .then(data => {
            if (data.location) {
                return {
                    latitude: data.location.lat,
                    longitude: data.location.lng
                }
            }
            else {
                throw Error('No location data!');
            }
        });
}

export function geoLookup() {
    const data = window.sessionStorage.getItem('geo');

    if (data)
        return Promise.resolve(JSON.parse(data));

    return new Promise((resolve, reject) => {
        const lookup = (latitude:number, longitude:number) => {
            apolloClient.query({
                query: nearestZipQueryDocument,
                variables: {latitude, longitude}
            }).then(({data}) => {
                if (data) {
                    const {nearestZip:location} = data;
                    window.sessionStorage.setItem('geo', JSON.stringify(location));
                    resolve(location);
                }
                else {
                    reject();
                }
            }).catch(reject);
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords:{latitude, longitude}}) => {
                console.log(`looking up location using coordinates from navigator.geolocation`);
                lookup(latitude, longitude);
            }, () => {
                getLatitudeLongitude()
                    .then(({latitude, longitude}) => {
                        lookup(latitude, longitude);
                    })
                    .catch(reject);
            })
        }
    });
}