import React, {useEffect} from 'react';

import {normalizeUUID} from "../../lib/misc";
import linkQueryDocument from '../../gql/Link';
import tokenLoginMutationDocument from '../../gql/TokenLogin';
import {useMutation, useQuery} from "@apollo/client";
import useRedirect from "../../lib/use-redirect";
import Spinner from "../../components/Spinner";
import {saveJWT} from "../../lib/authentication";

const TokenLogin = (props:any) => {
    const {match:{params:{token}}} = props;
    const linkId = normalizeUUID(token);
    const redirect = useRedirect();

    const {loading, data, error} = useQuery(linkQueryDocument, {
        variables: {
            input: linkId
        }
    });

    const [login, {called:calledLogin}] = useMutation(tokenLoginMutationDocument);

    useEffect(() => {
        if (loading) {
            //console.log('loading...');
            return;
        }

        if (calledLogin) {
            //console.log('called login...');
            return;
        }

        //console.log('in TokenLogin', {calledLogin});

        if (error) {
            console.error(error);
            redirect('/login', {error: 'Invalid or Expired Token'});
            return;
        }

        if (!(data && data.linkById)) {
            redirect('/dashboard/');
            return;
        }

        const {linkById:{path, data: state}} = data;

        //console.log(`logging in using linkId ${linkId}`);

        login({
            variables: {
                input: {
                    token: linkId
                }
            }
        })
            .then(result => {
                //console.log({result});

                const jwt = result && result.data && result.data.tokenLogin && result.data.tokenLogin.jwtToken;

                if (!jwt)
                    throw new Error('failed!');

                saveJWT(jwt).then(() => {
                    //console.log(`redirecting to ${path}`, state, jwt);

                    redirect(path, state);
                });
            })
            .then()
            .catch((e:any) => {
                console.log(e);
                redirect('/login', {error: 'Invalid or Expired Token'});
            });
    }, [loading, data, error, linkId, redirect, login, calledLogin]);

    return (
        <Spinner/>
    )
};

export default TokenLogin;