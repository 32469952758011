import {graphql} from "./types";

export const PotentialProviderFragment = graphql(`
    fragment PotentialProviderFragment on Provider {
        nodeId
        id
        name
        title
        phone
        email
        image {
            url
        }
        info
        transactions {
            nodes {
                nodeId
                address1
                address2
                city
                state
                zip
                closeDate
                closePrice
                location
                lookupFailed
            }
        }
        profileComplete
        geoJson
        engagebay
        status
        syntheticStatus
        presentedNames
        selectedNames
        approvalRequested
        public
        approved
        signed
        docusignStatus
        docusignSigned
        bizDev
        account {
            id
            firstName
            lastName
        }
    }
`);

export const dealPotentialProvidersQueryDocument = graphql(`
    query DealPotentialProviders($dealUniqueId: String!) {
        dealPotentialProviders(dealUniqueId: $dealUniqueId) {
            nodes {
                distance
                provider {
                    ...PotentialProviderFragment
                }
            }
        }
    }
`);

export const cityStatePotentialProvidersQueryDocument = graphql(`
    query CityStatePotentialProviders($city: String!, $state: String!) {
        cityStatePotentialProviders(city:$city, state:$state) {
            nodes {
                distance
                provider {
                    ...PotentialProviderFragment
                }
            }
        }
    }
`);


export const zipPotentialProvidersQueryDocument = graphql(`
    query ZipPotentialProviders($zip: String!) {
        zipPotentialProviders(zip:$zip) {
            nodes {
                distance
                provider {
                    ...PotentialProviderFragment
                }
            }
          }
    }
`);
