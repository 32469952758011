import React, {MouseEventHandler} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const OverlayContainer = styled.div`
    position: fixed;
    z-index: 65534;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface Props {
    onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    children: React.ReactNode;
}

const Overlay: React.FC<Props> = ({onClick, ...props}) => {
    const container = document.getElementById('overlay');

    if (!container) {
        console.error(`couldn't find #overlay?!?`);
        return null;
    }

    const clickHandler: MouseEventHandler<HTMLDivElement> = e => {
        e.stopPropagation();

        if (e.target !== e.currentTarget || !onClick)
            return;

        e.preventDefault();

        onClick(e);
    }

    return ReactDOM.createPortal((
            <OverlayContainer onClick={clickHandler} {...props}/>
        ),
        container
    );
}

export default Overlay;