import gql from "graphql-tag";

export default gql`
    mutation LoginAs($input: LoginAsInput!) {
        loginAs(input: $input) {
            link {
                id
            }
        }
    }
`;